import {ErrorHandler, NgModule} from '@angular/core';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AccountResolver, CoreModule, createTranslateLoader, I18nService} from '@app/core';
import {AccountProvider} from '@app/shared/service/account.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {IonicStorageModule} from '@ionic/storage-angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from '@env/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApplicationService, ScreenSizeGuard, ScreenSizeSuperGuard} from '@app/shared/service/application.service';
import {AppRate} from '@awesome-cordova-plugins/app-rate/ngx';
import {ServiceWorkerModule} from '@angular/service-worker';
import {provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage} from 'ngx-webstorage';
import {FirebaseService} from '@app/shared/service/firebase.service';
import {SocketService} from '@app/shared/service/socket.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FullstoryServiceService} from '@app/shared/service/fullstory-service.service';
import {MatNativeDateModule} from '@angular/material/core';
import {PreviousRouteService} from '@app/shared/service/previous-route.service';
import {AppSessionService} from '@app/shared/service/app-session.service';
import {FraudulentService} from '@app/shared/service/fraudulent-service';
import {AppSettingsService} from '@app/shared/service/app-settings.service';
import {WhatsNewComponent} from '@app/components/whats-new/whats-new.component';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Drivers } from '@ionic/storage';
import {Network} from '@awesome-cordova-plugins/network/ngx';
import {IsOnlineModule} from '@app/components/is-online/is-online.module';
import {MessagesService} from '@app/shared/service/messages.service';
import {BidiModule} from '@angular/cdk/bidi';
import {RouteMonitoringModule} from "@app/map/route-monitoring/route-monitoring.module";

@NgModule({
    declarations: [AppComponent, WhatsNewComponent],
    imports: [
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
          driverOrder: [Drivers.LocalStorage, Drivers.IndexedDB, Drivers.SecureStorage]
        }),
        AppRoutingModule,
        HttpClientModule,
        CoreModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
          }
        }),
        BrowserAnimationsModule,
        NgxMaterialTimepickerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.serviceWorker}),
        MatDialogModule,
        MatProgressBarModule,
        MatNativeDateModule,
        IsOnlineModule,
        BidiModule,
        RouteMonitoringModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        AppRate,
        ApplicationService,
        AccountProvider,
        AppSettingsService,
        FirebaseService,
        SocketService,
        AccountResolver,
        ScreenSizeGuard,
        ScreenSizeSuperGuard,
        FirebaseX,
        FullstoryServiceService,
        I18nService,
        PreviousRouteService,
        AppSessionService,
        FraudulentService,
        InAppBrowser,
        Network,
        MessagesService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        provideNgxWebstorage(
          withNgxWebstorageConfig({ separator: ':', caseSensitive: true }),
          withLocalStorage(),
          withSessionStorage()
        )
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

import {Injectable} from '@angular/core';
import {Events} from '@app/shared/service/events';
import {environment} from '@env/environment';
import {AccountProvider} from '@app/shared/service/account.service';
import {Storage} from '@ionic/storage-angular';
import Tracker from '@openreplay/tracker';

@Injectable()
export class FullstoryServiceService {
    constructor(private events: Events,
                private accountService: AccountProvider,
                private storage: Storage) {

    }

    public static stopFullstory = false;
    tracker = undefined;
    private fullStoryLoaded = false;
    private account;
    private fullstoryMetadata = {};

    async loadAccount() {
        const account = await this.storage.get('account');
        if (account != undefined) {
            this.account = account;
        }
    }

    async start() {
        await this.loadAccount();
        this.initTracker();
        this.events.subscribe('fullstory:data', (data) => {
            this.fullstoryMetadata = Object.assign({}, this.fullstoryMetadata, data);
            if (this.fullStoryLoaded) {
                this.loadFullstoryUserVars();
            }
        });

        this.accountService.getAuthenticationState().subscribe(account => {
            this.account = account;
            if (this.fullStoryLoaded) {
                this.loadFullstoryAccount();
            }
        });

        if (this.account != undefined) {
            setTimeout(() => {
                this.initFullStory();
            }, 1000);
        } else {
            setTimeout(() => {
                this.initFullStory();
            }, 15000);
        }
    }

  private initFullStory() {
    const enableMonitor = environment.environment == 'production' &&
      this.account?.enableMonitoring == true &&
      !FullstoryServiceService.stopFullstory;

    if (!enableMonitor || this.tracker?.isActive()) {
      return;
    }

    this.tracker.start().then(() => {
      // console.log('tracker started');
      this.fullStoryLoaded = true;
      this.loadFullstoryUserVars();
      this.loadFullstoryAccount();
    });

  }

    private loadFullstoryAccount() {
        setTimeout(() => {
            if (this.tracker != undefined && this.account != undefined) {
              this.tracker.setUserID(this.account.email);
              this.tracker.setMetadata('displayName', this.account.firstName + ' ' + this.account.lastName);
              this.tracker.setMetadata('userId', this.account._id);
              this.tracker.setMetadata('organizationId', this.account.sellerId);
              this.tracker.setMetadata('appVersion', environment.appVersion);
            }
        });
    }

    private loadFullstoryUserVars() {
        setTimeout(() => {
            if (this.tracker != undefined) {
              Object.keys(this.fullstoryMetadata).forEach(key => {
                this.tracker.setMetadata(key, this.fullstoryMetadata[key]);
              });
            }
        });
    }

    private initTracker() {
      if (this.tracker != undefined) {
        return;
      }
      this.tracker = new Tracker({
        projectKey: 'NA7QY9mBdf55s1307AaQ',
        ingestPoint: 'https://replay.logistia.app/ingest',
        __DISABLE_SECURE_MODE: true,
        consoleMethods: ['error'],
        respectDoNotTrack: false,
        resourceBaseHref: environment.portalUrl,
        obscureTextEmails: false,
        obscureTextNumbers: false,
        obscureInputEmails: false,
        obscureInputDates: false
      });
    }

}

import {Injectable} from '@angular/core';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {Diagnostic} from '@awesome-cordova-plugins/diagnostic/ngx';
import {
  AskMobilePermissionComponent
} from '@app/map/route-monitoring/ask-mobile-permission/ask-mobile-permission.component';
import {TranslateService} from '@ngx-translate/core';
import {Storage} from '@ionic/storage-angular';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {AccountProvider} from '@app/shared/service/account.service';
import moment from 'moment';
import {environment} from '@env/environment';

@Injectable()
export class AskPermissionService {

  constructor(private platform: Platform,
              private diagnostic: Diagnostic,
              private device: Device,
              private alertController: AlertController,
              private storage: Storage,
              private modalController: ModalController,
              private translate: TranslateService,
              private accountProvider: AccountProvider) {

  }

  lgLocationPermission = 'lg_location_permission';
  initialConfigured = false;
  private isGPSAlertOpen = false;
  private hasPermission;

  async checkForMobileLocation(force?: boolean) {
    if (!this.platform.is('cordova')) {
      return true;
    }
    if (this.initialConfigured && !force) {
      return this.hasPermission;
    }

    if (force == undefined && !(await this.askUserForPermission())) {
      await this.updateAccountStatus(LocationPermissions.DENIED);
      this.hasPermission = false;
      this.initialConfigured = true;
      return this.hasPermission;
    }

    let requestLocation = await this.requestLocation();

    if ([LocationPermissions.NOT_REQUESTED, LocationPermissions.DENIED_ONCE, LocationPermissions.DENIED].includes(requestLocation)) {
      await this.doAskForMobilePermission();
      requestLocation = await this.requestLocation();
    }

    this.hasPermission = [LocationPermissions.GRANTED, LocationPermissions.GRANTED_WHEN_IN_USE].includes(requestLocation);

    if ([LocationPermissions.GRANTED, LocationPermissions.GRANTED_WHEN_IN_USE].includes(requestLocation)) {
      await this.checkGPS(true);
    }
    this.updateAccountStatus(requestLocation);
    this.initialConfigured = true;
    return this.hasPermission;
  }

  async doAskForMobilePermission() {
    await this.diagnostic.requestLocationAuthorization('when_in_use').catch(e => {
      console.error(e);
    });
    // const requestLocation = await this.requestLocation();
    // this.updateAccountStatus(requestLocation);
    /*if (requestLocation == LocationPermissions.GRANTED_WHEN_IN_USE) {
      await this.diagnostic.requestLocationAuthorization('always').catch(e => {
        console.error(e);
      });
    }*/
  }

  async checkGPS(cannotDisable?: boolean) {
    const enabled = await this.diagnostic.isLocationEnabled().catch(e => {
      console.error(e);
    });
    if (!enabled) {
      await this.openAlertController(cannotDisable);
    }
  }

  async openAlertController(cannotDisable: boolean) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        animated: true,
        header: this.translate.instant('service.location.locationOff'),
        message: this.translate.instant('service.location.locationReason'),
        backdropDismiss: cannotDisable == true,
        buttons: [{
          text: this.translate.instant('service.location.turnLocationOn'),
          handler: async () => {
            this.isGPSAlertOpen = false;
            await this.diagnostic.switchToLocationSettings();
            resolve({});
          }
        }]
      });
      if (!this.isGPSAlertOpen) {
        this.isGPSAlertOpen = true;
        await alert.present();
        await alert.onDidDismiss().then(() => {
          this.isGPSAlertOpen = false;
          return resolve({});
        });
      } else {
        reject({error: 'Alert already opened'});
      }
    });
  }

  async requestLocation(): Promise<LocationPermissions> {
    const status = await this.diagnostic.getLocationAuthorizationStatus().catch(e => {
      console.error(e);
      return this.diagnostic?.permissionStatus?.RESTRICTED;
    });
    console.log(status);
    if (status == this.diagnostic?.permissionStatus?.GRANTED) {
      console.log('Permission granted always');
      return LocationPermissions.GRANTED;
    } else if (status == this.diagnostic?.permissionStatus?.DENIED) {
      console.log('Permission denied');
      return LocationPermissions.DENIED;
    } else if (status == this.diagnostic?.permissionStatus?.DENIED_ONCE) {
      console.log('Permission not requested');
      return LocationPermissions.DENIED_ONCE;
    } else if (status == this.diagnostic?.permissionStatus?.NOT_REQUESTED) {
      console.log('Permission not requested');
      return LocationPermissions.NOT_REQUESTED;
    } else if (status == this.diagnostic?.permissionStatus?.DENIED_ALWAYS) {
      console.log('Permission denied always');
      return LocationPermissions.DENIED_ALWAYS;
    } else if (status == this.diagnostic?.permissionStatus?.RESTRICTED) {
      console.log('Permission restricted');
      return LocationPermissions.RESTRICTED;
    } else if (status == this.diagnostic?.permissionStatus?.GRANTED_WHEN_IN_USE) {
      console.log('Permission granted when in use');
      return LocationPermissions.GRANTED_WHEN_IN_USE;
    } else {
      return LocationPermissions.GRANTED;
    }
  }

  async askUserForPermission() {
    let askForPermission: {
      granted: boolean,
      date: Date
    } = await this.storage.get(this.lgLocationPermission);

    let refMinutes = 10080; // 7 days
    if (environment.environment == 'QA' || environment.environment == 'development') {
      refMinutes = 1;
    }

    if ((askForPermission as unknown) == true) {
      await this.storage.set(this.lgLocationPermission, {granted: true, date: new Date()});
      askForPermission = {granted: true, date: new Date()};
    } else if ((askForPermission as unknown) == false) {
      askForPermission = undefined;
    }

    if (askForPermission?.granted === true) {
      return true;
    }

    const canAsk = askForPermission == undefined ||
      (askForPermission?.granted === false &&
        askForPermission?.date != undefined &&
        Math.abs(moment(askForPermission.date).diff(moment(), 'minutes')) > refMinutes
      );

    if (!canAsk) {
      return false;
    }


    const modal = await this.modalController.create({
      component: AskMobilePermissionComponent,
      backdropDismiss: false
    });

    await modal.present();

    return modal.onDidDismiss().then(async resp => {
      await this.storage.set(this.lgLocationPermission, {granted: resp?.data === true, date: new Date()});
      return resp?.data === true;
    }).catch(resp => {
      console.error(resp);
      return false;
    });
  }

  async updateAccountStatus(status: LocationPermissions) {
    if (this.accountProvider.account.navigation == undefined) {
      this.accountProvider.account.navigation = {};
    }
    if (this.accountProvider.account.navigation.locationPermission != status) {
      this.accountProvider.account.navigation.locationPermission = status;
      await this.accountProvider.updateNavigation(this.accountProvider.account);
    }
  }


  async openAppSettings() {
    const alert = await this.alertController.create({
      header: this.translate.instant('mapPage.askMobilePermission.allowLocation'),
      subHeader: this.translate.instant('mapPage.askMobilePermission.allowPermissionFromSettings'),
      buttons: [{
        text: this.translate.instant('generic.okButton'),
        handler: value => {
          this.diagnostic.switchToSettings();
        }
      }]
    });
    await alert.present();
  }
}

enum LocationPermissions {
  GRANTED = 'GRANTED',
  DENIED = 'DENIED',
  DENIED_ONCE = 'DENIED_ONCE',
  NOT_REQUESTED = 'NOT_REQUESTED',
  DENIED_ALWAYS = 'DENIED_ALWAYS',
  RESTRICTED = 'RESTRICTED',
  GRANTED_WHEN_IN_USE = 'GRANTED_WHEN_IN_USE'
}

import {Injectable} from '@angular/core';
import BackgroundGeolocation from 'cordova-background-geolocation-lt';
import {environment} from '@env/environment';
import {IOrder} from '@app/shared/dto/order.model';
import {TokenService} from '@app/shared/service/token.service';
import {AccountProvider} from '@app/shared/service/account.service';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import {Events} from '@app/shared/service/events';

@Injectable()
export class CordovaLocationTrackingService {
  private isConfigured = false;
  private currentTrackingOrderId;

  constructor(private tokenService: TokenService,
              private accountProvider: AccountProvider,
              private translate: TranslateService,
              private events: Events) {
  }


  public async configure(order: IOrder) {
    const token = await this.tokenService.getToken();
    if (!this.isConfigured) {
      await BackgroundGeolocation.ready({
        reset: true,
        debug: environment.environment != 'production',
        logLevel: BackgroundGeolocation.LOG_LEVEL_ERROR,
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        distanceFilter: 70,
        url: environment.serverUrl + '/app/route/locationv2',
        stopOnTerminate: true,
        startOnBoot: false,
        autoSync: true,
        autoSyncThreshold: this.accountProvider.account.appSettings?.delivery?.multiDayRoute ? 10 : 7,
        batchSync: true,
        preventSuspend: true,
        // heartbeatInterval: 60,
        triggerActivities: 'in_vehicle',
        foregroundService: false,
        activityType: BackgroundGeolocation.ACTIVITY_TYPE_AUTOMOTIVE_NAVIGATION,
        motionTriggerDelay: 30000,
        maxBatchSize: 50,
        notification: {
          title: this.translate.instant('service.location-trackers.subheader')
        },
        headers: {
          'app-version': environment.appVersion
        },
        authorization: {
          strategy: 'JWT',
          accessToken: token,
        },
        locationAuthorizationRequest: 'Always',
        backgroundPermissionRationale: {
          title: this.translate.instant('service.location-trackers.backgroundPermission.title'),
          message: this.translate.instant('service.location-trackers.backgroundPermission.message'),
          positiveAction: this.translate.instant('service.location-trackers.backgroundPermission.positiveAction'),
          negativeAction: this.translate.instant('generic.cancel')
        }
      });
      await this.checkIfRunning();
      await BackgroundGeolocation.removeGeofences();
      await this.handleRouteGeofences();
      this.isConfigured = true;
    }

    const extras = {
      deliveryGroup: order?.deliveryGroup,
      orderId: order?._id,
      userId: this.accountProvider.account?._id,
      sellerId: this.accountProvider.account?.sellerId,
      deliveryDate: moment.tz(order?.deliveryDate, this.accountProvider.account?.appSettings?.timeZone).startOf('day').toDate(),
      timeZone: this.accountProvider.account?.appSettings?.timeZone
    };
    setTimeout(async () => {
      await BackgroundGeolocation.setConfig({
        extras: JSON.parse(JSON.stringify(extras))
      });
      if (order?._id != undefined && this.currentTrackingOrderId != order?._id) {
        this.currentTrackingOrderId = order._id;
        await BackgroundGeolocation.sync().catch(err => console.error('SYNC ', err));
        await BackgroundGeolocation.resetOdometer().catch(err => console.error('Reset odometer', err));
      }
    }, 300);
  }

  private async checkIfRunning() {
    const state = await BackgroundGeolocation.getState();
    if (state?.enabled) {
      await BackgroundGeolocation.stop();
    }
  }

  public async addGeofences(orders: { orderId: string, location: number[], type: string }[], routes: {
    identifier: string,
    routeId: string,
    location: number[]
  }[]) {
    if (!this.isConfigured) {
      return;
    }
    const savedGeofences: string[] = (await BackgroundGeolocation.getGeofences())?.map(value => value.identifier) ?? [];
    const newGeofences = orders?.map(o => o.orderId) ?? [];
    routes?.forEach(r => newGeofences.push(r.identifier));
    const toRemove = _.difference(savedGeofences, newGeofences);
    const geofences = [];
    for (const order of orders) {
      if (order.location == undefined || order.location?.length != 2) {
        continue;
      }
      if (savedGeofences.includes(order.orderId)) {
        continue;
      }
      geofences.push({
        identifier: order.orderId,
        radius: 200,
        latitude: order.location[1],
        longitude: order.location[0],
        notifyOnEntry: true,
        notifyOnExit: true,
        extras: {
          orderId: order.orderId,
          type: order.type
        }
      });
    }
    if (routes != undefined && routes.length > 0) {
      for (const route of routes) {
        if (savedGeofences.includes(route.identifier)) {
          continue;
        }
        geofences.push({
          identifier: route.identifier,
          radius: 200,
          latitude: route.location[1],
          longitude: route.location[0],
          notifyOnEntry: true,
          notifyOnExit: true,
          extras: {
            routeId: route.routeId,
            type: 'END_DEPOT'
          }
        });
      }
    }
    if (toRemove?.length > 0) {
      for (const i of toRemove) {
        await BackgroundGeolocation.removeGeofence(i);
      }
    }
    if (geofences.length > 0) {
      await BackgroundGeolocation.addGeofences(geofences);
    }
  }

  private async handleRouteGeofences() {
    if (this.accountProvider.account?.online?.onlineRequired === true) {
      return;
    }
    BackgroundGeolocation.onGeofence(geofenceEvent => {
      console.log('Entered geofence ', geofenceEvent);
      if (geofenceEvent?.extras?.type == 'END_DEPOT') {
        if (geofenceEvent.action == 'ENTER' && geofenceEvent?.extras?.routeId != undefined) {
          this.events.publish('route:arrivedAtEndDepot');
        } else if (geofenceEvent.action == 'EXIT' && geofenceEvent?.extras?.orderId != undefined) {
          this.events.publish('route:arrivedAtEndDepot');
        }
      }
    });
  }

  public async startLocation() {
    const state = await BackgroundGeolocation.getState();
    if (!state?.enabled) {
      console.log('START BG Geolocation');
      await BackgroundGeolocation.start();
    }
    BackgroundGeolocation.getState().then(state => {
      if (state?.enabled) {
        BackgroundGeolocation.changePace(true);
      }
    });
  }

  public async stopLocation() {
    const state = await BackgroundGeolocation.getState();
    if (state?.enabled) {
      console.log('STOP BG Geolocation');
      await BackgroundGeolocation.stop().catch(err => console.error(err));
      setTimeout(async () => {
        await BackgroundGeolocation.sync().catch(err => console.error(err));
      }, 1000);
    }
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsOnlineDirective} from "@app/components/is-online/is-online.directive";


@NgModule({
  declarations: [IsOnlineDirective],
  exports: [IsOnlineDirective],
  imports: [
    CommonModule
  ]
})
export class IsOnlineModule {
}

import {Injectable, Type} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Platform, ToastController} from '@ionic/angular';
import {environment} from '@env/environment';
import {SwUpdate} from '@angular/service-worker';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import {Storage} from '@ionic/storage-angular';
import {PreviousRouteService} from '@app/shared/service/previous-route.service';


export function getDeviceComponent(mobileComponent: Type<any>, desktopComponent: Type<any>) {
  return window.innerWidth < 768 ? mobileComponent : desktopComponent;
}

export function isMobile() {
  let check = false;
  ((a) => {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || (window as any).opera);

  return check || window.innerWidth <= 768 || window.matchMedia('(orientation: portrait)').matches;
}

export function isDesktop() {
  return !isMobile();
}

@Injectable()
export class ScreenSizeGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const strings = state.url.split('?');
    if (isMobile()) {
      this.router.navigate([strings[0] + '/' + (route?.data?.screen?.mobile ?? 'mobile')], {
        queryParamsHandling: 'merge',
        queryParams: state.root.queryParams
      });
    } else {
      this.router.navigate([strings[0] + '/' + (route?.data?.screen?.desktop ?? 'desktop')], {
        queryParamsHandling: 'merge',
        queryParams: state.root.queryParams
      });
    }
    return false;
  }

}

@Injectable()
export class ScreenSizeSuperGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const strings = state.url.split('?');
    const allowedParams = {};

    if (route.data.allowedParams != undefined && route.data.allowedParams.length > 0) {
      for (const key of Object.keys(state.root.queryParams)) {
        if (route.data.allowedParams.indexOf(key) > -1) {
          allowedParams[key] = state.root.queryParams[key];
        }
      }
    }

    if (isMobile()) {
      if (strings[0].endsWith('/desktop')) {
        this.router.navigate([strings[0].replace('desktop', 'mobile')], {
          queryParamsHandling: 'merge',
          queryParams: allowedParams
        });
      }
    } else {
      if (strings[0].endsWith('/mobile')) {
        this.router.navigate([strings[0].replace('mobile', 'desktop')], {
          queryParamsHandling: 'merge',
          queryParams: allowedParams
        });
      }
    }
    return true;
  }

}

@Injectable()
export class ApplicationService {

  constructor(private platform: Platform,
              private swUpdate: SwUpdate,
              private toastController: ToastController,
              private translate: TranslateService,
              private router: Router,
              private _dateAdapter: DateAdapter<Date>,
              private activatedRoute: ActivatedRoute,
              private previousRouteService: PreviousRouteService,
              private storage: Storage) {
    if (window.location !== window.parent.location) {
      this.hideSidebar = true;
      this.hideLogout = true;
      window.addEventListener('message', (event) => {
        if (event.data != undefined && event.data.type != undefined && event.data.type == 'iframeLoaded') {
          this.parentFrame = event.data.source;
        }
      });
    }
  }
  public parentFrame;
  public hideSidebar = false;
  public hideLogout = false;

  debounce;

  updateApp() {
    if (environment.environment == 'QA' && !this.platform.is('cordova')) {
      this.serviceWorkerUpdate();
    }
  }

  private serviceWorkerUpdate() {
    this.swUpdate.versionUpdates.subscribe(evt => {
      if (evt.type == 'VERSION_READY') {
        this.handleAppRestart();
      }
    });
    setInterval(() => {
      this.swUpdate.versionUpdates.subscribe(evt => {
        if (evt.type == 'VERSION_READY') {
          this.handleAppRestart();
        }
      });
    }, 3600000);

  }

  private handleAppRestart() {
    this.toastController.create({
      message: this.translate.instant('service.application-service.updateAvailable'),
      buttons: [
        {
          text: this.translate.instant('service.application-service.updateButton'), handler: () => {
            window.location.reload();
          }
        },
        {
          text: this.translate.instant('service.application-service.cancelButton')
        }
      ]
    }).then((toast) => {
      toast.present();
    });
  }
  screenResize(innerWidth: number) {
    if (this.debounce) {
      clearTimeout(this.debounce);
      delete this.debounce;
    }
    this.debounce = setTimeout(() => {
      const isMob = isMobile();
      if (isMob && this.router.url.includes('desktop')) {
        const newUrl = this.router.url.replace('desktop', 'mobile');
        const strings = newUrl.split('?');
        this.router.navigate([strings[0]], {queryParams: this.parseQuery(strings[1])});
      } else if (!isMob && this.router.url.includes('mobile')) {
        const newUrl = this.router.url.replace('mobile', 'desktop');
        const strings = newUrl.split('?');
        this.router.navigate([strings[0]], {queryParams: this.parseQuery(strings[1])});
      }
    }, 1000);
  }

  parseQuery(queryString) {
    if (queryString == undefined || queryString == '') {
      return {};
    }
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  setAppLanguage(langKey: any) {
    this._dateAdapter.setLocale(langKey);
  }

  loadIonicFonts() {
    const originalFetch = (window as any).fetch;
    const xhrToResponse = xhr => {
      const headers = new Headers();
      xhr.getAllResponseHeaders().trim().split(/[\r\n]+/).forEach(line => {
        const [name, value] = line.split(': ', 2);
        headers.append(name, value);
      });
      return new Response(xhr.responseText, {
        status: xhr.status,
        statusText: xhr.statusText,
        headers,
      });
    };
    (window as any).fetch = (...args) => {
      const [url] = args;
      if (typeof url === 'string' && url.match(/\.svg$/)) {
        return new Promise((resolve, reject) => {
          const req = new XMLHttpRequest();
          req.open('GET', url, true);
          req.addEventListener('load', () => {
            resolve(xhrToResponse(req));
          });
          req.addEventListener('error', reject);
          req.send();
        });
      } else {
        return originalFetch.apply(window, args);
      }
    };
  }

  public async saveState(routes?: string[]) {
    if (routes != undefined && routes.length > 0) {
      await this.storage.set('appState', routes);
    } else {
      await this.storage.set('appState', [this.router.url]);
    }
  }

  public async redoState(event) {
    const appState: string[] = await this.storage.get('appState');
    if (appState == undefined) {
      return;
    }
    for (const state of appState) {
      await this.navigate(state, event, 0);
    }
    await this.storage.remove('appState');
  }

  private async navigate(url, event, ittr) {
    await this.router.navigateByUrl(url, {state: event});
    return new Promise((resolve) => {
      setTimeout(() => {
        if (this.router.url.startsWith(url)) {
          resolve({});
        } else {
          ittr++;
          if (ittr < 5) {
            this.navigate(url, event, ittr).then(() => {
              resolve({});
            });
          } else {
            resolve({});
          }
        }
      }, 1000);
    });
  }

  public async clearState() {
    await this.storage.remove('appState');
  }
}

import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {IOrder} from '@app/shared/dto/order.model';
import {AccountProvider} from '@app/shared/service/account.service';
import {Storage} from '@ionic/storage-angular';

@Injectable()
export class BrowserLocationTrackingService {

  private watchId: number | null = null;
  private locations = [];
  private mapOrder: IOrder;
  private storageKey = 'trackedLocations';

  constructor(private http: HttpClient,
              private accountProvider: AccountProvider,
              private storage: Storage) {
  }

  async init() {
    await this.storage.create();
    await this.loadLocations();
  }

  private async loadLocations() {
    const storedLocations = await this.storage.get(this.storageKey);
    if (storedLocations) {
      this.locations = storedLocations;
    }
  }

  private async saveLocation(location) {
    let storedLocations = await this.storage.get(this.storageKey);
    if (storedLocations == undefined) {
      storedLocations = [];
    }
    storedLocations.push(location);
    await this.storage.set(this.storageKey, this.storageKey);
  }

  public async startWatchingLocation(order: IOrder): Promise<void> {
    this.mapOrder = order;
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    if (this.watchId != undefined) {
      return;
    } else {
      await this.init();
    }

    this.watchId = navigator.geolocation.watchPosition(async (position) => {
      const location = this.mapPositionToLocation(position);
      this.locations.push(location);
      await this.saveLocation(location);

      if (this.locations.length >= 10) {
        this.sendLocations();
      }
    }, (error) => {
      console.error('Error obtaining location', error);
    }, {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 27000
    });
  }

  private mapPositionToLocation(position: GeolocationPosition) {
    return JSON.parse(JSON.stringify({
      timestamp: position.timestamp ?? new Date().toISOString(),
      coords: {
        latitude: position.coords?.latitude,
        longitude: position.coords?.longitude,
        accuracy: position.coords?.accuracy,
        speed: position.coords?.speed,
        heading: position.coords?.heading,
        altitude: position.coords?.altitude
      },
      extras: {
        deliveryGroup: this.mapOrder?.deliveryGroup,
        orderId: this.mapOrder?._id,
        deliveryDate: this.mapOrder?.deliveryDate,
        userId: this.accountProvider?.account?._id,
        sellerId: this.accountProvider?.account?.sellerId,
        timeZone: this.accountProvider.account?.appSettings?.timeZone
      }
    }));
  }

  private sendLocations(): void {
    if (this.locations.length > 0) {
      const root = {location: this.locations};
      this.http.post(`${environment.serverUrl}/app/route/locationv2`, root).subscribe({
        next: async () => {
          console.log('Locations sent successfully');
          this.locations = [];
          await this.storage.remove(this.storageKey);
        },
        error: (error) => console.error('Error sending locations', error)
      });
    }
  }

  public stopWatchingLocation(): void {
    if (this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId);
      this.watchId = null;
    }

    if (this.locations.length > 0) {
      this.sendLocations();
    }
  }

}

import {Injectable} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {SwitchAccountComponent} from '@app/components/switch-account/switch-account.component';
import {AccountProvider} from '@app/shared/service/account.service';
import {TokenService} from '@app/shared/service/token.service';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwitchAccountService {
  public canSwitchAccount = false;

  constructor(private modalController: ModalController,
              private tokenService: TokenService,
              private http: HttpClient,
              private accountProvider: AccountProvider,
              private loadingCtrl: LoadingController) {
    this.tokenService.refreshTokens.subscribe(value => {
      this.canSwitchAccount = value?.length > 1;
    });
  }


  async openSwitchAccount() {
    if (!this.canSwitchAccount) {
      return;
    }
    const modal = await this.modalController.create({
      component: SwitchAccountComponent
    });

    await modal.present();
    modal.onDidDismiss().then(resp => {
      if (resp.role == 'doQuickLogin') {
        this.doQuickLogin(resp.data);
      }
    });
  }

  async doQuickLogin(account) {
    const loading = await this.loadingCtrl.create();
    await loading.present();
    try {
      const resp = await firstValueFrom(this.http.post<{
        token: string
      }>('/app/login/quick-login', account));
      await this.accountProvider.softLogout();
      await this.tokenService.setToken(resp.token);
      setTimeout(() => {
        window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 300);
      }, 300);
    } finally {
      await loading.dismiss();
    }
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {GenericSearchDto, PageResponse} from "@app/shared/dto/generic-search.dto";
import {IMessage} from "@app/shared/model/messages.model";

@Injectable()
export class MessagesService {
  public messagesSubject = new BehaviorSubject<PageResponse<IMessage>>({content: []});
  public lastSearch: GenericSearchDto = new GenericSearchDto({}, [{field: 'createdAt', direction: -1}], '');
  private totalElements;
  public hasMessages = false;

  constructor(private http: HttpClient) {

  }

  async getMessages(search?) {
    this.lastSearch = search ?? this.lastSearch;
    const iMessagesResponse = await this.http.post<PageResponse<IMessage>>('/app/messages/list', search ?? this.lastSearch).toPromise();
    this.lastSearch.totalElements = iMessagesResponse.totalElements;
    this.totalElements = iMessagesResponse.totalElements;
    iMessagesResponse.content = [...this.messagesSubject.value.content, ...iMessagesResponse.content];
    this.messagesSubject.next(iMessagesResponse);
    this.checkForUnreadMessages();
    return iMessagesResponse;
  }

  checkForUnreadMessages() {
    this.hasMessages = this.messagesSubject.value.content.find(msg => !msg.read) != undefined;
  }

  changeMessage(_id, action, value) {
    return this.http.post('/app/messages/changeMessage', {_id: _id, action: action, value: value}).toPromise();
  }
}

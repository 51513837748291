import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Events} from '@app/shared/service/events';
import {Platform} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {AppSessionService} from '@app/shared/service/app-session.service';
import {TokenService} from "@app/shared/service/token.service";
import {AppOnlineService} from "@app/shared/service/app-online.service";

export const sendMessage = 'ws:sendMessage';
export const receiveMessage = 'ws:receiveMessage';

@Injectable()
export class SocketService {
  private activeConnection = new BehaviorSubject(false);
  private socket: WebSocket;
  private connectionRetry;
  private pingPongRetry;

  private channels: string[] = [];
  private session = null;
  constructor(private tokenService: TokenService,
              private events: Events,
              private platform: Platform,
              private appSessionService: AppSessionService,
              private appOnlineService: AppOnlineService) {
    this.session = this.appSessionService.generator();
    this.platform.pause.subscribe(pause => {
      if (this.connectionRetry != undefined) {
        this.clearIntervals();
        this.activeConnection.next(false);
      }
    });
    this.platform.resume.subscribe(pause => {
      this.initSocket();
    });
    appOnlineService.status.subscribe(status => {
      if (status) {
        this.initSocket();
      } else {
        this.disconnect();
      }
    });
  }


  public initSocket(): void {
    if (this.activeConnection.value) {
      return;
    }
    if (this.socket != undefined && (this.socket.readyState == WebSocket.OPEN || this.socket.readyState == WebSocket.CONNECTING)) {
      return;
    }
    this.clearIntervals();
    if (this.socket != undefined) {
      if (this.socket.readyState == WebSocket.OPEN || this.socket.readyState == WebSocket.CONNECTING) {
        this.socket.close();
      }
      delete this.socket;
    }
    this.tokenService.getToken().then(token => {
      if (token != undefined) {
        this.socket = new WebSocket(environment.socket.url + `?Authorization=${token}&s=${this.session}&v=${environment.appVersion}`);
        this.setSocketHandlers();
      }
    });
  }

  private clearIntervals() {
    if (this.connectionRetry != undefined) {
      clearInterval(this.connectionRetry);
      delete this.connectionRetry;
    }
    if (this.pingPongRetry) {
      clearInterval(this.pingPongRetry);
      delete this.pingPongRetry;
    }
  }

  private setSocketHandlers() {
    this.socket.onopen = (e) => {
      this.activeConnection.next(true);
      this.clearIntervals();
      this.pingPong();
      this.resubscribeToAllChannels();
      this.events.subscribe(sendMessage, (data) => {
        this.socket.send(data);
      });
    };
    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.category) {
        this.events.publish(receiveMessage + ':' + data.category, data.data);
      }
    };
    this.socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        if (this.activeConnection.value) {
          this.activeConnection.next(false);
          if (this.connectionRetry == undefined) {
            this.startConnectionRetry();
          }
        }
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log('[close] Connection died. Starting reconnect');
        this.activeConnection.next(false);
        if (this.connectionRetry == undefined) {
          this.startConnectionRetry();
        }
      }
    };
    this.socket.onerror = (error) => {
      console.error(`[error] ${error}`);
    };
  }

  startConnectionRetry() {
    if (!this.appOnlineService.status.getValue()) {
      return;
    }
    const subscription = this.activeConnection.subscribe(active => {
      if (active) {
        this.events.publish('ws:reconnect');
        setTimeout(() => {
          subscription.unsubscribe();
        });
      }
    });
    this.connectionRetry = setInterval(() => {
      this.initSocket();
    }, 5000);
  }

  connect(): void {
    this.initSocket();
  }

  disconnect(): void {
    this.activeConnection.next(false);
    if (this.socket) {
      this.socket.close(1000, 'User logged out');
    }
  }

  pingPong() {
    this.pingPongRetry = setInterval(() => {
      if (this.activeConnection.value && this.socket) {
        this.socket.send(JSON.stringify({action: 'ping'}));
      }
    }, 540000);
  }

  resubscribeToAllChannels() {
    if (this.channels.length > 0) {
      this.socket.send(JSON.stringify({action: 'channel', paths: this.channels, activity: 'subscribe'}));
    }
  }

  subscribeToChannels(paths: string[]) {
    if (paths == undefined || paths.length == 0) {
      return;
    }
    const pathToSub = [];
    paths.forEach(p => {
      if (this.channels.indexOf(p) == -1) {
        pathToSub.push(p);
        this.channels.push(p);
      }
    });
    if (this.activeConnection.value && this.socket && pathToSub.length > 0) {
      this.socket.send(JSON.stringify({action: 'channel', paths: pathToSub, activity: 'subscribe'}));
    }
  }

  unsubscribeFromChannels(paths: string[]) {
    if (paths == undefined || paths.length == 0) {
      return;
    }
    this.channels = this.channels.filter(c => paths.indexOf(c) == -1);
    if (this.activeConnection.value && this.socket) {
      this.socket.send(JSON.stringify({action: 'channel', paths, activity: 'unsubscribe'}));
    }
  }
}


import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-free-trial-expired',
  templateUrl: './free-trial-expired.component.html',
  styleUrls: ['./free-trial-expired.component.scss'],
})
export class FreeTrialExpiredComponent implements OnInit {

  constructor(private modalController: ModalController,
              private route: Router) {
  }

  ngOnInit() {
  }

  goToSubscription() {
    this.modalController.dismiss().then(() => {
      this.route.navigate(['subscription', 'new-subscription']);
    });
  }
}

import {NgModule} from '@angular/core';
import {HasAnyAuthorityDirective} from '@app/core/has-any-authority/has-any-authority.directive';
import {HasAnySubscriptionDirective} from '@app/core/has-any-authority/has-any-subscription.directive';
import {WhenHasSubscription} from '@app/core/has-any-authority/when-has-subscription.pipe';

@NgModule({
    declarations: [HasAnyAuthorityDirective, HasAnySubscriptionDirective, WhenHasSubscription],
    exports: [HasAnyAuthorityDirective, HasAnySubscriptionDirective, WhenHasSubscription],
    providers: [WhenHasSubscription]
})
export class HasAnyAuthorityModule {

}

import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-ask-mobile-permission',
    templateUrl: './ask-mobile-permission.component.html',
    styleUrls: ['./ask-mobile-permission.component.scss'],
})
export class AskMobilePermissionComponent implements OnInit {

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
    }

    dismissModal(allow) {
        this.modalController.dismiss(allow);
    }
}

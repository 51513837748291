<ion-header>
  <ion-toolbar>
    <ion-title>{{'components.free-trial-expired.title' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding white-background">
  <img class="upgrade-icon" src="assets/icon/start-subscription-logistia.png">
  <p><b>{{'components.free-trial-expired.msg1' | translate}}</b></p>
  <p>{{'components.free-trial-expired.msg2' | translate}}</p>
  <p>{{'components.free-trial-expired.msg3' | translate}}</p>
  <div style="text-align: right">
    <ion-button slot="end"
                (click)="goToSubscription()">{{'components.free-trial-expired.goToSubscription' | translate}}</ion-button>
  </div>
</ion-content>

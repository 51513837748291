<ion-content class="white">

    <img class="location-image" src="../../../../assets/icon/location-popup.svg">

    <h3 class="ion-margin-horizontal">{{'mapPage.askMobilePermission.allowLocationTitle' | translate }}</h3>

    <p class="ion-text-wrap ion-text-justify ion-margin-horizontal">
        {{'mapPage.askMobilePermission.currentLocationUsage1' | translate }}
    </p>
    <p class="ion-text-wrap ion-text-justify ion-margin-horizontal">
        {{'mapPage.askMobilePermission.currentLocationUsage2' | translate }}
    </p>

</ion-content>
<ion-footer class="button-footer">
    <ion-button fill="clear" shape="round" expand="full" (click)="dismissModal(false)">{{'mapPage.askMobilePermission.skipLocationButton' | translate }}</ion-button>
    <ion-button shape="round" expand="full" (click)="dismissModal(true)">{{'mapPage.askMobilePermission.allowLocation' | translate }}</ion-button>
</ion-footer>

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from '@app/app.module';
import {environment} from '@env/environment';

if (environment.production) {
    enableProdMode();
    if (environment.environment == 'production' && window) {
        window.console.log = function() {
        };
    }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

import {Injectable} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ActivateSubscriptionNowComponent} from "@app/components/activate-subscription-now/activate-subscription-now.component";

@Injectable({
    providedIn: 'root'
})
export class ActivateSubscriptionNowService {

    constructor(private modalController: ModalController) {
    }

    async showActivateSubscription(subscription: {
      errorMessage?: string;
      subscription?: string;
      feature?: string;
    }) {
        let modal = await this.modalController.create({
            component: ActivateSubscriptionNowComponent,
            componentProps: {
                subscription: subscription
            }
        });

        await modal.present();
    }
}

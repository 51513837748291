import {Inject, Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import * as _ from 'underscore';

import {Logger} from './logger.service';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AccountProvider} from '@app/shared/service/account.service';
import {environment} from '@env/environment';
import {Direction} from "@angular/cdk/bidi";
import {DOCUMENT} from "@angular/common";

const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param {string} s The string to extract for translation.
 * @return {string} The same string.
 */
export function extract(s: string) {
    return s;
}

export function createTranslateLoader(http: HttpClient) {
    const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': 'logistia.app'
    });
    http.options('', {headers: httpHeaders});
    return new TranslateHttpLoader(http, `/${environment.appVersion}/i18n/`, '.json');
}

@Injectable()
export class I18nService {

    defaultLanguage: string;
    supportedLanguages: string[];
    position: Direction = 'ltr';
    rtlLanguages = ['he'];

    constructor(private translateService: TranslateService, private accountProvider: AccountProvider,
                @Inject(DOCUMENT) private document: Document) {
        // Embed languages to avoid extra HTTP requests
        // this.init('en', ['de', 'en', 'es', 'fr', 'it', 'ro']);
        this.init('en', ['en', 'es', 'fr', 'ro', 'it', 'de', 'pl', 'pt', 'tr', 'he']);
    }

    /**
     * Initializes i18n for the application.
     * Loads language from local storage if present, or sets default language.
     * @param {!string} defaultLanguage The default language to use.
     * @param {Array.<String>} supportedLanguages The list of supported languages.
     */
    init(defaultLanguage: string, supportedLanguages: string[]) {
        this.defaultLanguage = defaultLanguage;
        this.supportedLanguages = supportedLanguages;
        this.language = '';

        this.translateService.onLangChange
            .subscribe((event: LangChangeEvent) => {
                localStorage.setItem(languageKey, event.lang);
                this.handleLanguageAttributes(event.lang);
            });
    }

    /**
     * Sets the current language.
     * Note: The current language is saved to the local storage.
     * If no parameter is specified, the language is loaded from local storage (if present).
     * @param {string} language The IETF language code to set.
     */
    set language(language: string) {
        language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang() || this.getNativeLanguage();
        let isSupportedLanguage = _.includes(this.supportedLanguages, language);

        // If no exact match is found, search without the region
        if (language && !isSupportedLanguage) {
            language = language.split('-')[0];
            language = this.supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
            isSupportedLanguage = Boolean(language);
        }

        // Fallback if language is not supported
        if (!isSupportedLanguage) {
            language = this.defaultLanguage;
        }

        log.debug(`Language set to ${language}`);
        this.translateService.use(language);
        this.handleLanguageAttributes(language);
    }

    handleLanguageAttributes(lang) {
      this.document.documentElement.lang = lang;
      if (this.rtlLanguages.indexOf(lang) > -1) {
        this.document.documentElement.dir = 'rtl';
        this.position = 'rtl';
      } else {
        this.document.documentElement.dir = 'ltr';
        this.position = 'ltr';
      }
    }

    changeLanguage(language: string) {
        this.language = language;
    }

    /**
     * Gets the current language.
     * @return {string} The current language code.
     */
    get language(): string {
        return this.translateService.currentLang;
    }

    getNativeLanguage() {
        if (window != undefined && window.navigator != undefined && window.navigator.language != undefined) {
            return window.navigator.language.split('-')[0].toLowerCase();
        } else {
            return undefined;
        }

    }

}

import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import * as _ from 'underscore';

@Injectable()
export class PreviousRouteService {

  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  public async getPreviousUrl(defaultRoute: string[]) {
    if (window?.history?.length > 1) {
      window.history.back();
    } else {
      await this.router.navigate(defaultRoute);
    }
  }

  getUniqueHistory() {
    return _.uniq(this.history.reverse(), element => element.split('?')[0]).reverse();
  }
}

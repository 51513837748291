import {Component, HostListener} from '@angular/core';

import {ActionSheetController, AlertController, ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {AccountProvider} from '@app/shared/service/account.service';
import {environment} from '@env/environment';
import {AppRate} from '@awesome-cordova-plugins/app-rate/ngx';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {ApplicationService, isMobile} from '@app/shared/service/application.service';
import momentTz from 'moment-timezone';
import {FirebaseService} from '@app/shared/service/firebase.service';
import {FullstoryServiceService} from '@app/shared/service/fullstory-service.service';
import {I18nService} from '@app/core';
import {PreviousRouteService} from '@app/shared/service/previous-route.service';
import { Storage } from '@ionic/storage-angular';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MessagesService} from '@app/shared/service/messages.service';
import {SwitchAccountService} from "@app/components/switch-account/switch-account.service";
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    canRate = false;
    public env = environment;
    showTimeWarning;
    loading = false;

    constructor(private platform: Platform,
                private splashScreen: SplashScreen,
                private statusBar: StatusBar,
                private appRate: AppRate,
                public accountProvider: AccountProvider,
                private router: Router,
                public applicationService: ApplicationService,
                private firebaseService: FirebaseService,
                private fullStory: FullstoryServiceService,
                public i18nService: I18nService,
                private modalController: ModalController,
                private alertController: AlertController,
                private actionSheetCtrl: ActionSheetController,
                private storage: Storage,
                private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                public messagesService: MessagesService,
                public switchAccountService: SwitchAccountService,
                // !!!! Do not delete, it does not instantiate route history if not present !!!!
                private previousRouteService: PreviousRouteService
                // !!!! Do not delete, it does not instantiate route history if not present !!!!
    ) {
        this.initializeApp();
        this.handleLoading();
    }

    initializeApp() {
        this.platform.ready().then(async () => {
            await this.storage.create();
            if (this.platform.is('cordova')) {
              this.canRate = true;
              setTimeout(() => {
                this.configRateApp();
                this.setStatusbarColor();
              }, 2000);
              this.handleBackButton();
            }
            if (isMobile()) {
                setTimeout(() => {
                    if ((window as any).tidioChatApi != undefined) {
                        (window as any).tidioChatApi.display(false);
                    }
                }, 300);
            }
            this.applicationService.updateApp();
            this.startAnalytics();
            this.firebaseService.startFirebaseApp();
            this.fullStory.start();
            this.registerIcons();
            document.addEventListener('resume', (event) => {
              if ((event as any)?.pendingResult != undefined) {
                setTimeout(() => {
                  this.applicationService.redoState(event);
                }, 1000);
              }
            }, false);
        });

    }

    setStatusbarColor() {
      if (this.platform.is('cordova') && (this.platform.is('ios') || this.platform.is('android'))) {
        if (this.platform.is('android')) {
          this.statusBar.styleLightContent();
          this.statusBar.backgroundColorByHexString('000000');
        } else if (this.platform.is('ipad')) {
          this.statusBar.overlaysWebView(false);
          this.statusBar.backgroundColorByHexString('cfe1fc');
        } else {
          this.statusBar.styleDefault();
        }
        this.splashScreen.hide();
      }
    }

      handleBackButton() {
        this.platform.backButton.subscribeWithPriority(0, async (processNextHandler) => {
          if (await this.modalController.getTop() != undefined) {
            await this.modalController.dismiss();
          } else if (await this.alertController.getTop() != undefined) {
            await this.alertController.dismiss();
          } else if (await this.actionSheetCtrl.getTop() != undefined) {
            await this.actionSheetCtrl.dismiss();
          } else {
            processNextHandler();
          }
        });
      }

    configRateApp(ignore?) {
      if (this.appRate != null) {
        this.appRate.setPreferences({
          usesUntilPrompt: 3,
          storeAppURL : {
            ios: '1513820235',
            android: 'market://details?id=fespore.logistia.client'
          },
          simpleMode: true
        });
        if (!ignore) {
          this.appRate.promptForRating(false);
        }
      }
    }

    rateApp() {
        this.configRateApp(true);
        this.appRate.navigateToAppStore();
    }

    handleLoading() {
      this.router.events.subscribe((event) => {
        switch (true) {
          case event instanceof NavigationStart: {
            this.loading = true;
            break;
          }

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            this.loading = false;
            break;
          }
          default: {
            break;
          }
        }
      });
    }

    startAnalytics() {
        const trackingCode = 'G-L927E7RPZF';
        if (typeof gtag !== 'undefined') {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    gtag('config', trackingCode, {page_path: event.urlAfterRedirects});
                }
            });
        }

        this.accountProvider.getAuthenticationState().subscribe(account => {
            if (account.langKey != undefined && account.langKey != '') {
                this.i18nService.language = account.langKey;
                this.applicationService.setAppLanguage(account.langKey);
            }
            if (account != undefined && account.appSettings != undefined && account.appSettings.timeZone != undefined
                && momentTz.tz(account.appSettings.timeZone).format('Z') != momentTz().format('Z')) {
                this.showTimeWarning = account.appSettings.timeZone;
                if (isMobile()) {
                    setTimeout(() => {
                        this.showTimeWarning = undefined;
                    }, 5000);
                }
            }
            if (typeof gtag !== 'undefined') {
                gtag('config', trackingCode, {send_page_view: false, user_id: account._id});
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        setTimeout(() => {
            this.applicationService.screenResize(event.target.innerWidth);
        });
    }

    registerIcons() {
      this.matIconRegistry.addSvgIcon('generateRoute', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icon/optimize_route.svg'));
    }

}

import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TokenService {

  private token;
  public refreshTokens = new BehaviorSubject([]);
  constructor(private storage: Storage) {
    this.getToken();
    this.getQuickLoginAccounts();
  }

  async setToken(token) {
    return this.storage.set('authorization-token', token).then(() => {
      return this.getToken();
    });
  }

  async getToken() {
    if (this.token) {
      return this.token;
    }
    await this.storage.create();
    return this.storage.get('authorization-token').then(token => {
      if (token) {
        this.token = token;
      } else {
        delete this.token;
      }
      return this.token;
    });
  }

  async removeToken() {
    return this.storage.remove('authorization-token').then(() => {
      return this.token;
    });
  }

  async saveQuickLoginAccount(account, refreshToken) {
    if (refreshToken == undefined || refreshToken == '') {
      return;
    }
    let quickLogin: any[] = await this.storage.get('quick-login-token');
    if (quickLogin == undefined) {
      quickLogin = [];
    }

    const found = quickLogin.find(acc => acc._id == account._id);
    if (found) {
      found.refreshToken = refreshToken;
      found.platform = account.integrations.map(i => i.platformName).join(', ');
      found.name = `${account.firstName ?? ''} ${account.lastName ?? ''}`;
      found.email = account.email;
    } else {
      quickLogin.push({
        _id: account._id,
        refreshToken,
        platform: account.integrations.map(i => i.platformName).join(', '),
        name: `${account.firstName ?? ''} ${account.lastName ?? ''}`,
        email: account.email
      });
    }

    await this.storage.set('quick-login-token', quickLogin);
    this.loadRefreshTokens();
  }

  async getQuickLoginAccounts() {
    await this.storage.create();
    let quickLogins = await this.storage.get('quick-login-token');
    if (quickLogins == undefined) {
      quickLogins = [];
    }
    this.loadRefreshTokens();
    return quickLogins;
  }


  saveQuickLogin(quickLogin: any[]) {
    this.storage.set('quick-login-token', quickLogin ?? []);
    this.loadRefreshTokens();
  }

  loadRefreshTokens() {
    this.storage.get('quick-login-token').then((resp) => {
      this.refreshTokens.next(resp ?? []);
    });
  }
}

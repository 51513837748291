import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AccountProvider} from '@app/shared/service/account.service';

@Directive({
    selector: '[hasAnyAuthority]'
})
export class HasAnyAuthorityDirective {
    private authorities: string[];

    constructor(private accountProvider: AccountProvider,
                private templateRef: TemplateRef<any>,
                private viewContainerRef: ViewContainerRef) {
    }

    @Input()
    set hasAnyAuthority(value: string | string[]) {
        this.authorities = typeof value === 'string' ? [value] : value;
        this.updateView();
        this.accountProvider.getAuthenticationState().subscribe(identity => this.updateView());
    }

    private updateView(): void {
        Promise.resolve(this.hasAnyAuthorityDirect(this.authorities)).then(result => {
            this.viewContainerRef.clear();
            if (result) {
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        });
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.accountProvider.isLoggedIn || !this.accountProvider.account || !this.accountProvider.account.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.accountProvider.account.authorities.includes(authorities[i])) {
                return true;
            }
        }

        return false;
    }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AccountProvider} from '@app/shared/service/account.service';
import * as _ from 'underscore';


@Injectable()
export class RedirectGuard implements CanActivate {

    constructor(private accountProvider: AccountProvider, private router: Router) {

    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.accountProvider.getAccountDetails().then(account => {
            if (_.intersection(route.data.authorities, account.authorities).length > 0) {
                return true;
            }
            let redirectTo;
            for (const redirect of route.data.redirect) {
                if (_.intersection(redirect.authorities, account.authorities).length > 0) {
                    redirectTo = redirect.redirectTo;
                    break;
                }
            }
            if (redirectTo != undefined) {
                setTimeout(() => {
                    this.router.navigate([redirectTo]);
                }, 0);
                return false;
            }
            return true;
        }).catch(() => {
            return true;
        });
    }

}

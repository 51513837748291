import {Pipe, PipeTransform} from '@angular/core';
import {ActiveSubscriptionService} from '@app/shared/service/subscription.service';
import _ from 'underscore';
import {AccountProvider} from '@app/shared/service/account.service';

@Pipe({
  name: 'whenHasSubscription'
})
export class WhenHasSubscription implements PipeTransform {
  subscriptionRoles: string[];
  ignore = true;

  constructor(
    private activeSubscriptionService: ActiveSubscriptionService,
    private accountProvider: AccountProvider
  ) {
    this.activeSubscriptionService.activeSubscriptionSubject.subscribe(subscription => {
      this.subscriptionRoles = _.flatten(subscription.map(value => value.subscriptionRoles));
    });
    this.accountProvider.getAuthenticationState().subscribe(account => {
      this.ignore = false;
      this.ignore = account.authorities.indexOf('EDIT_SUBSCRIPTION') > -1;
    });
  }

  transform(otherCondition: boolean, subscription: string): boolean {
    if (this.ignore) { return true; }
    return this.subscriptionRoles == undefined || this.subscriptionRoles.indexOf(subscription) > -1;
  }

}

import 'firebase/auth';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {FirebaseApp, getApps, initializeApp} from 'firebase/app';
import {Storage} from '@ionic/storage-angular';
import {Analytics, getAnalytics} from 'firebase/analytics';
import {getAuth, signInWithCustomToken} from 'firebase/auth';

@Injectable()
export class FirebaseService {
    private initiated = false;
    private firebaseApp = new Subject<FirebaseApp>();
    private firebase: FirebaseApp;
    public analytics: Analytics;

    constructor(private http: HttpClient,
                private platform: Platform,
                private storage: Storage) {

    }

    getFirebaseApp(): Observable<FirebaseApp> {
        if (this.initiated) {
            return of(this.firebase);
        } else {
            this.startFirebaseApp();
            return this.firebaseApp.asObservable();
        }
    }

    startFirebaseApp() {
        if (this.initiated) {
            return;
        }
        this.initiated = true;
        if (getApps().length === 0) {
            this.firebase = initializeApp(environment.firebaseConfig);
        } else {
            this.firebase = getApps()[0];
        }
        if (environment.environment == 'production' && !this.platform.is('cordova')) {
          this.analytics = getAnalytics(this.firebase);
        }
    }

    async signInUserInFirebase() {
      if (getApps().length > 0 && getAuth().currentUser != undefined) {
        return;
      }
      this.startFirebaseApp();
      let token = await this.storage.get('firebaseToken');
      if (token == undefined) {
        const resp = await this.http.get<{ token: string }>('/app/login/firebase').toPromise();
        token = resp.token;
        await this.storage.set('firebaseToken', token);
        return;
      }
      await signInWithCustomToken(getAuth(), token).then(() => {
        this.firebaseApp.next(this.firebase);
      }).catch(async () => {
        const resp = await this.http.get<{ token: string }>('/app/login/firebase').toPromise();
        token = resp.token;
        await this.storage.set('firebaseToken', token);
        await signInWithCustomToken(getAuth(), token);
      });
    }

    async stopFirebaseApp() {
      if (!this.initiated || (this.platform.is('cordova') && this.platform.is('ios'))) {
        return;
      }
      this.initiated = false;
      if ((await this.storage.get('firebaseToken')) != undefined) {
        await this.storage.remove('firebaseToken');
        await getAuth().signOut();
      }
    }
}

import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {clear, createStore, get, set, UseStore} from 'idb-keyval';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  private longTermCache = createStore('logistia-long-term', 'logistia-long-term');
  private shortTermCache = createStore('logistia-short-term', 'logistia-short-term');

  private longTermCachePatterns = [
    '/app/settings/appSettings',
    '/app/employee/allEmployees',
    '/app/geolocate/decodeLocationByIp',
    '/app/login/accountAndToken',
    '/billing/subscription/activeSubscriptions',
    '/app/integration/integrations',
    '/app/order/filledDates',
    '/stock/product/products'
  ];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'GET' && (request.url.includes('/app/') || request.url.includes('/stock/') || request.url.includes('/billing/'))) {
      const isPatternLong = this.longTermCachePatterns.some(pattern => pattern.includes(request.url));
      const cache = isPatternLong ? this.longTermCache : this.shortTermCache;

      return next.handle(request).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.putInCache(request, event, cache);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status == 400) {
            throw error;
          }
          return from(this.getFromCache(request, cache)).pipe(
            switchMap(cachedResponse => {
              if (cachedResponse) {
                return of(cachedResponse);
              } else {
                throw error;
              }
            })
          );
        })
      );
    } else {
      return next.handle(request);
    }
  }

  async getFromCache(request: HttpRequest<unknown>, cache: UseStore): Promise<HttpResponse<unknown> | undefined> {
    const cachedResponse = await get(request.urlWithParams, cache);

    if (cachedResponse) {
      return new HttpResponse({body: cachedResponse, status: 200});
    }

    return undefined;
  }

  async putInCache(request: HttpRequest<unknown>, response: HttpResponse<unknown>, cache: UseStore): Promise<void> {
    const clonedResponse = response.clone();
    const responseBody = clonedResponse.body;
    await set(request.urlWithParams, responseBody, cache);
  }

  async logout() {
    await clear(this.longTermCache);
    await clear(this.shortTermCache);
  }
}

<ion-header class="white">
    <ion-toolbar color="secondary" class="white">
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen class="white">
    <ion-img class="image-subscription" src="assets/icon/start-subscription-logistia.png"></ion-img>
    <h4 class="ion-text-center emphasize">{{'components.activate-subscription-now.upgradeSubscriptionMessage' | translate }}</h4>
    <ion-item *ngIf="subscription == undefined || subscription?.subscription == 'optimalRouting'" class="white ion-margin-start">
        <ion-text class="ion-margin-start center-margin">
          <span style="white-space: pre-line">{{'components.activate-subscription-now.generateRoutesFast' | translate }}</span>
        </ion-text>
    </ion-item>
    <ion-item *ngIf="subscription?.subscription == 'maxNumberOfOrders'" class="white ion-margin-start ion-text-center">
        <ion-text class="ion-margin-start ion-text-wrap">{{'components.activate-subscription-now.exceededCurrentNoOfOrders' | translate }}</ion-text>
    </ion-item>
    <ion-item *ngIf="subscription == undefined || subscription?.subscription == 'customerOrderNotification'" class="white ion-margin-start ion-text-center">
        <ion-text class="ion-margin-start">{{'components.activate-subscription-now.etaEmailNotification' | translate }}</ion-text>
    </ion-item>
    <ion-item *ngIf="subscription == undefined || subscription?.subscription == 'stockAlerts'" class="white ion-margin-start ion-text-center">
        <ion-text class="ion-margin-start">{{'components.activate-subscription-now.productStockAlerts' | translate }}</ion-text>
    </ion-item>
    <div class="ion-text-center">
      <ion-button class="ion-margin-horizontal" style="margin-top: 30px" shape="round"  (click)="activateSubscription()">
          {{'components.activate-subscription-now.unlockPremiumFeatures' | translate }}
      </ion-button>
    </div>
</ion-content>


import {Injectable} from "@angular/core";
import {Storage} from "@ionic/storage-angular";
import moment from "moment";
import {FullstoryServiceService} from "@app/shared/service/fullstory-service.service";
import {Platform} from "@ionic/angular";

@Injectable()
export class FraudulentService {
  key = "lg_fa"

  constructor(private storage: Storage,
              private platform: Platform) {
    this.init();
  }

  private init() {
    this.platform.ready().then(() => {
      this.checkFraudulentFlag().then(resp => {
        if (resp) {
          FullstoryServiceService.stopFullstory = true;
          this.stopTidio();
        }
      })
    })
  }

  async addFraudulentFlag() {
    await this.storage.set(this.key, {value: true, date: moment().toDate()});
    this.stopTidio();
  }

  async checkFraudulentFlag() {
    let fraud = await this.storage.get(this.key);
    if (fraud == undefined) {
      return false;
    }
    return Math.abs(moment(fraud.date).diff(moment(), 'days')) <= 2;
  }

  private stopTidio(){
    if ((<any>window).tidioChatApi != undefined) {
      setTimeout(() => {
        (<any>window).tidioChatApi.display(false);
      });
    }
  }
}

import {Injectable} from '@angular/core';
import {AccountProvider} from '@app/shared/service/account.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Events} from '@app/shared/service/events';
import {receiveMessage} from '@app/shared/service/socket.service';
import {LocationTrackingService} from '@app/map/route-monitoring/location-tracking.service';

@Injectable()
export class RouteMonitoringService {

  constructor(private trackingService: LocationTrackingService,
              private account: AccountProvider,
              private events: Events,
              private http: HttpClient) {
    this.watchForLocationChange();
  }

  private locationBuffer: MonitoringCoords[] = [];
  public hasCurrentPosition = {};
  locationLogs = new BehaviorSubject<ILocation[]>([]);

  locationLoading = false;

  watchForLocationChange() {
    this.events.subscribe(receiveMessage + ':currentLocation', (data: CurrentLocation[]) => {
      if (data != undefined && data.length > 0) {
        const logs = this.locationLogs.getValue();
        data.forEach(d => {
          const iLocation = logs.filter(l => l.accountId == d.accountId);
          if (iLocation == undefined || iLocation.length == 0) {
            const items: any = {
              currentLocation: d.currentLocation,
              date: d.date,
              accountId: d.accountId
            };
            if (d.deliveryGroup != null) {
              items.deliveryGroup = d.deliveryGroup;
            }
            logs.push(items);
          } else {
            iLocation.forEach(l => {
              l.currentLocation = d.currentLocation;
              l.date = d.date;
              if (d.deliveryGroup != null) {
                l.deliveryGroup = d.deliveryGroup;
              }
            });
          }
          this.hasCurrentPosition[d.accountId] = true;
        });
        this.locationLogs.next(logs);
      }
    });
  }

  getLocationCurrentPosition(userIds, date, deliveryGroup): Observable<ILocation[]> {
    if (this.account.account.authorities.indexOf('VIEW_EMPLOYEE_LIVE_PATH') == -1) {
      return;
    }
    if (userIds == undefined || userIds.length == 0 || date == undefined) {
      return;
    }
    Object.keys(this.hasCurrentPosition).forEach(accId => {
      this.hasCurrentPosition[accId] = false;
    });
    this.getLocationsToServer(date, deliveryGroup, userIds).then((data: ILocation[]) => {
      this.locationLogs.next([]);
      if (data != undefined && data.length > 0) {
        data.forEach(d => {
          this.hasCurrentPosition[d.accountId] = true;
        });
        this.locationLogs.next(data);
      }
    });
  }

  getLocationsToServer(date, deliveryGroup, userIds) {
    if (this.locationLoading == true) {
      return new Promise(resolve => resolve([]));
    }
    this.locationLoading = true;
    const params: any = {
      deliveryDate: new Date(date).toISOString(),
      userIds: Array.isArray(userIds) ? userIds.join(',') : userIds
    };
    if (deliveryGroup != undefined) {
      params.deliveryGroup = deliveryGroup;
    }
    return this.http.get('/app/route/location', {
      params
    }).toPromise().finally(() => {
      this.locationLoading = false;
    });
  }

}

export interface MonitoringCoords extends Coordinates {
  time: Date;
}

interface Coordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}

export interface ILocation {
  currentLocation?: number[];
  coords?: {
    timestamp?: Date,
    location?: number[],
    altitude?: number,
    accuracy?: number,
    altitudeAccuracy?: number,
    heading?: number,
    speed?: number
  }[];
  date?: Date;
  accountId?: string;
  path?: string;
  deliveryDate?: Date;
  deliveryGroup?: string;
  orderId?: string;
}

interface CurrentLocation {
  currentLocation: number[];
  path: string;
  accountId: string;
  date: Date;
  deliveryGroup: string;
}

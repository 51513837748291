import { Component, OnInit } from '@angular/core';
import {Platform} from "@ionic/angular";
import {InAppBrowser} from "@awesome-cordova-plugins/in-app-browser/ngx";
import {Storage} from "@ionic/storage-angular";
import {environment} from "@env/environment";

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss'],
})
export class WhatsNewComponent implements OnInit {
  url = 'https://logistia.app/changelog';
  hasNewVersion = false;
  constructor(private platform: Platform,
              private iab: InAppBrowser,
              private storage: Storage) { }

  ngOnInit() {
    this.checkForVersion();
  }

  async checkForVersion() {
    let hasVersion = await this.storage.get("checked-appversion");
    if (hasVersion == undefined) {
      this.hasNewVersion = false;
      await this.storage.set("checked-appversion", environment.appVersion);
      return;
    }
    this.hasNewVersion = this.versionCompare(environment.appVersion, hasVersion,{}) > 0;
  }

  async openPage() {
    await this.storage.set("checked-appversion", environment.appVersion);
    if (this.platform.is('cordova')) {
      this.iab.create(this.url, '_blank', 'location=yes');
    } else {
      window.open(this.url, '_blank');
    }
    this.checkForVersion();
  }

  private versionCompare(v1, v2, options) {
    var lexicographical = options && options.lexicographical,
      zeroExtend = options && options.zeroExtend,
      v1parts = v1.split('.'),
      v2parts = v2.split('.');
      v1parts.pop();
      v2parts.pop();

    function isValidPart(x) {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push("0");
      while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (var i = 0; i < v1parts.length; ++i) {
      if (v2parts.length == i) {
        return 1;
      }

      if (v1parts[i] == v2parts[i]) {
        continue;
      }
      else if (v1parts[i] > v2parts[i]) {
        return 1;
      }
      else {
        return -1;
      }
    }

    if (v1parts.length != v2parts.length) {
      return -1;
    }

    return 0;
  }
}

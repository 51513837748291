import {Injectable} from '@angular/core';
import {EmployeeService} from '@app/shared/service/employee.service';
import {ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class EmployeeResolverService implements Resolve<any>, CanActivate {

    constructor(private employeeProvider: EmployeeService) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        await this.employeeProvider.loadEmployeesInBackground();
        return true;
    }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        await this.employeeProvider.loadEmployeesInBackground();
        return true;
    }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {environment} from '@env/environment';
import {AppSessionService} from "@app/shared/service/app-session.service";
import {TokenService} from "@app/shared/service/token.service";

/**
 * Prefixes all requests with `environment.serverUrl` and add sessionId.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  constructor(private appSessionService: AppSessionService,
              private tokenService: TokenService) {
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/app/') || request.url.includes('/stock/') || request.url.includes('/billing/')) {
      return from(this.handleRequest(request)).pipe(
        switchMap(newRequest => next.handle(newRequest))
      );
    } else {
      return next.handle(request);
    }
  }

  private async handleRequest(request: HttpRequest<unknown>): Promise<HttpRequest<unknown>> {
    const session = await this.appSessionService.getSession();
    const token = await this.tokenService.getToken();

    let headers: any = {
      'lg-session': session,
      'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'app-version': environment.appVersion
    };
    if (token != undefined) {
      headers.Authorization = `Bearer ${token}`;
    }
    const page = request.params.get('page');
    if (page != undefined) {
      headers['lg-page'] = page.toString();
      request.params.delete('page');
    }

    const modifiedRequest = request.clone({
      setHeaders: headers,
      url: environment.serverUrl + request.url
    });

    return modifiedRequest;
  }


}

export enum AuthorityEnum {
  VIEW_DASHBOARD = 'VIEW_DASHBOARD',
  VIEW_ORDERS = 'VIEW_ORDERS',
  EDIT_ORDERS = 'EDIT_ORDERS',
  VIEW_DELIVERIES = 'VIEW_DELIVERIES',
  EDIT_DELIVERIES = 'EDIT_DELIVERIES',
  VIEW_EMPLOYEES = 'VIEW_EMPLOYEES',
  EDIT_EMPLOYEES = 'EDIT_EMPLOYEES',
  VIEW_INTEGRATIONS = 'VIEW_INTEGRATIONS',
  EDIT_INTEGRATIONS = 'EDIT_INTEGRATIONS',
  VIEW_PRODUCTS = 'VIEW_PRODUCTS',
  EDIT_PRODUCTS = 'EDIT_PRODUCTS',
  VIEW_ROUTES = 'VIEW_ROUTES',
  EDIT_ROUTES = 'EDIT_ROUTES',
  VIEW_ASSIGN_ORDERS = 'VIEW_ASSIGN_ORDERS',
  EDIT_ASSIGN_ORDERS = 'EDIT_ASSIGN_ORDERS',
  VIEW_SUPPLIERS = 'VIEW_SUPPLIERS',
  EDIT_SUPPLIERS = 'EDIT_SUPPLIERS',
  VIEW_APP_SETTINGS = 'VIEW_APP_SETTINGS',
  EDIT_APP_SETTINGS = 'EDIT_APP_SETTINGS',
  VIEW_SUBSCRIPTION = 'VIEW_SUBSCRIPTION',
  EDIT_SUBSCRIPTION = 'EDIT_SUBSCRIPTION',
  VIEW_UNSCHEDULED_ORDERS = 'VIEW_UNSCHEDULED_ORDERS',
  EDIT_UNSCHEDULED_ORDERS = 'EDIT_UNSCHEDULED_ORDERS',
  MAKE_DELIVERIES = 'MAKE_DELIVERIES',
  MAKE_PICKUPS = 'MAKE_PICKUPS',
  VIEW_ORDER_PRODUCT_PRICE = 'VIEW_ORDER_PRODUCT_PRICE',
  VIEW_ORDER_DELIVERY_PRICE = 'VIEW_ORDER_DELIVERY_PRICE',
  VIEW_ORDER_TOTAL_PRICE = 'VIEW_ORDER_TOTAL_PRICE',
  VIEW_PREPARE_DELIVERY = 'VIEW_PREPARE_DELIVERY',
  DELETE_ORDERS = 'DELETE_ORDERS',
  VIEW_ORDER_HISTORY = 'VIEW_ORDER_HISTORY',
  VIEW_ORDER_COMMENTS = 'VIEW_ORDER_COMMENTS',
  ADD_ORDER_COMMENTS = 'ADD_ORDER_COMMENTS',
  VIEW_ORDER_CUSTOMER_NAME = 'VIEW_ORDER_CUSTOMER_NAME',
  VIEW_ORDER_CUSTOMER_ADDRESS = 'VIEW_ORDER_CUSTOMER_ADDRESS',
  VIEW_ORDER_PAYMENT_INFO = 'VIEW_ORDER_PAYMENT_INFO',
  VIEW_ORDER_PLACED_ON = 'VIEW_ORDER_PLACED_ON',
  VIEW_ORDER_DELIVERY_TIME = 'VIEW_ORDER_DELIVERY_TIME',
  VIEW_ORDER_PRODUCTS = 'VIEW_ORDER_PRODUCTS',
  VIEW_ORDER_CUSTOMER_TELEPHONE = 'VIEW_ORDER_CUSTOMER_TELEPHONE',
  VIEW_ORDER_CUSTOMER_EMAIL = 'VIEW_ORDER_CUSTOMER_EMAIL',
  VIEW_ORDER_CUSTOMER_REVIEW = 'VIEW_ORDER_CUSTOMER_REVIEW',
  VIEW_ORDER_DELIVERY_EMPLOYEE = 'VIEW_ORDER_DELIVERY_EMPLOYEE',
  VIEW_ORDER_DELIVERY_INTERVAL = 'VIEW_ORDER_DELIVERY_INTERVAL',
  VIEW_ORDER_SERVICE_TIME = 'VIEW_ORDER_SERVICE_TIME',
  VIEW_PICKUPS = 'VIEW_PICKUPS',
  VIEW_REPORTS = 'VIEW_REPORTS',
  VIEW_STOCK_REQUEST = 'VIEW_STOCK_REQUEST',
  EDIT_STOCK_REQUEST = 'EDIT_STOCK_REQUEST',
  VIEW_NOTIFY_CUSTOMERS = 'VIEW_NOTIFY_CUSTOMERS',
  EDIT_NOTIFY_CUSTOMERS = 'EDIT_NOTIFY_CUSTOMERS',
  VIEW_CUSTOMERS = 'VIEW_CUSTOMERS',
  EDIT_CUSTOMERS = 'EDIT_CUSTOMERS',
  VIEW_PROOF_OF_DELIVERY = 'VIEW_PROOF_OF_DELIVERY',
  ADD_PROOF_OF_DELIVERY = 'ADD_PROOF_OF_DELIVERY',
  DELETE_PROOF_OF_DELIVERY = 'DELETE_PROOF_OF_DELIVERY',
  VIEW_CUSTOM_FIELDS = 'VIEW_CUSTOM_FIELDS',
  EDIT_CUSTOM_FIELDS = 'EDIT_CUSTOM_FIELDS',
  VIEW_EMPLOYEE_LIVE_PATH = 'VIEW_EMPLOYEE_LIVE_PATH',
  EDIT_ORDER_PRODUCTS = 'EDIT_ORDER_PRODUCTS',
  EDIT_ORDER_PAYMENT_STATUS = 'EDIT_ORDER_PAYMENT_STATUS',
  EDIT_ORDER_PAYMENT_MENTHOD = 'EDIT_ORDER_PAYMENT_MENTHOD'
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom, ReplaySubject} from 'rxjs';
import {Events} from '@app/shared/service/events';
import {receiveMessage} from '@app/shared/service/socket.service';
import * as _ from 'underscore';
import {Storage} from '@ionic/storage-angular';
import {TranslateService} from '@ngx-translate/core';
import {routeColor} from '@app/shared/dto/route.dto';

@Injectable({providedIn: 'root'})
export class EmployeeService {
  private loading = false;
  public employeesLoaded = false;
  employees: Array<any> = [];
  employeeObject = {};
  employeesColors: any = {};
  deliveryEmployees: Array<any> = [];
  pickupEmployees: Array<any> = [];
  routingEmployees: Array<any> = [];
  public employeesLoadedEvent = new BehaviorSubject<Array<any>>([]);
  constructor(public http: HttpClient,
              private event: Events,
              private storage: Storage,
              private translateService: TranslateService) {
    this.event.subscribe(receiveMessage + ':employees', (data) => {
      if (data.action == 'refresh') {
        this.getAllEmployees();
      } else if (data.action == 'updateStatus') {
        this.updateEmployeeStatus(data);
      }
    });
  }

  private async getAllEmployees() {
    const employees = await firstValueFrom(this.http.get<any[]>('/app/employee/allEmployees'));
    if (employees != undefined) {
      employees.forEach(emp => {
        this.calculateEmployeesRoles(emp);
        this.getRouteColorsFromEmployee(emp);
      });
      this.employees = employees;
      this.calculateEmployeesStatus();
      await this.storage.set('employees', employees);
      this.employeesLoadedEvent.next(employees);
    }
    return employees ?? [];
  }

  async createOrUpdateEmployee(account) {
    await this.http.post('/app/employee/createOrUpdateEmployee', account).toPromise();
    await this.loadEmployees(true);
  }

  findById(id) {
    return this.http.get('/app/employee/find', {params: {_id: id}}).toPromise().then(employee => {
      this.calculateEmployeesRoles(employee);
      return employee;
    });
  }

  async loadEmployees(force?: boolean): Promise<Array<any>> {
    if (force) {
      return this.getAllEmployees();
    } else {
      return new Promise<Array<any>>(resolve => {
        const subscription = this.employeesLoadedEvent.subscribe(emps => {
          if (emps != undefined) {
            setTimeout(() => {
              subscription.unsubscribe();
            });
            resolve(emps);
          }
        });
      });
    }
  }

  private calculateEmployeesStatus() {
    this.deliveryEmployees.splice(0, this.deliveryEmployees.length);
    this.pickupEmployees.splice(0, this.pickupEmployees.length);
    this.routingEmployees.splice(0, this.routingEmployees.length);

    this.deliveryEmployees.push(...this.employees.filter(value => value.authorities.indexOf('MAKE_DELIVERIES') > -1));
    this.pickupEmployees.push(...this.employees.filter(value => value.authorities.indexOf('MAKE_PICKUPS') > -1));
    this.routingEmployees.push(...this.employees.filter(value => value.authorities.indexOf('MAKE_PICKUPS') > -1 || value.authorities.indexOf('MAKE_DELIVERIES') > -1));
  }

  public getAccountName(deliveryAccountId) {
    if (deliveryAccountId == undefined || deliveryAccountId == '') {
      return this.translateService.instant('generic.orderActions.unassignedOrder');
    }
    const employeeObjectElement = this.employeeObject[deliveryAccountId];
    if (employeeObjectElement != undefined) {
      return `${employeeObjectElement.firstName ?? ''} ${employeeObjectElement.lastName ?? ''}`.trim();
    } else {
      return '';
    }
  }

  public calculateEmployeesRoles(emp) {
    const roleNames = [];
    for (const r of emp.roles) {
      const key = 'generic.roles.' + r;
      const translatedRole = this.translateService.instant(key);
      if (translatedRole && translatedRole != key) {
        roleNames.push(translatedRole);
      } else {
        roleNames.push(r);
      }
    }
    emp.roleNames = roleNames;
  }

  async getAllDeliveryEmployees() {
    return this.loadEmployees().then(emp => {
      return emp.filter(value => value.authorities.indexOf('MAKE_DELIVERIES') > -1 || value.authorities.indexOf('MAKE_PICKUPS') > -1);
    });
  }

  async getAllDeliveryEmployeesForSubscription() {
    return this.http.get('/app/employee/allEmployees', {params: {for: 'subscription'}}).toPromise().then((emp: any[]) => {
      return emp.filter(value => value.authorities.indexOf('MAKE_DELIVERIES') > -1 || value.authorities.indexOf('MAKE_PICKUPS') > -1);
    });
  }

  async getDeliveryEmployees() {
    return this.loadEmployees().then(emp => {
      return emp.filter(value => value.authorities.indexOf('MAKE_DELIVERIES') > -1 || value.authorities.indexOf('MAKE_PICKUPS') > -1);
    });
  }

  async resetEmployees() {
    this.employees = [];
    this.deliveryEmployees = [];
    this.employeesLoaded = false;
    return this.loadEmployees();
  }

  async deleteEmployee(_id: any) {
    await this.http.delete('/app/employee/delete', {params: {employeeId: _id}}).toPromise();
    await this.loadEmployees(true);
  }

  getRouteColorsFromEmployee(employee) {
    employee.firstName = employee.firstName || '';
    employee.lastName = employee.lastName || '';
    employee.zoneNames = employee.zoneRestrictions?.map(zone => zone?.properties?.name).filter(n => n != undefined && n != '').join(',') ?? '';
    if (employee.options == undefined) {
      employee.options = {};
    }
    this.employeeObject[employee._id] = employee;
    if (employee.options.routeColor != undefined) {
      this.employeesColors[employee._id] = employee.options.routeColor;
      return;
    }
    if (this.employeesColors[employee._id] != undefined) {
      employee.options.routeColor = this.employeesColors[employee._id];
      return;
    }


    if (this.employees.length == 1 && employee._id != undefined) {
      employee.options.routeColor = routeColor[0];
      this.employeesColors[employee._id] = routeColor[0];
      return;
    }

    const usedColors = this.employees.map(emp => {
      if (emp.options != undefined) {
        return emp.options.routeColor;
      } else {
        return undefined;
      }
    }).filter(c => c != undefined && c != '');
    const difference = _.difference(routeColor, usedColors);
    if (difference.length > 0) {
      employee.options.routeColor = _.sample(difference);
    } else {
      employee.options.routeColor = _.sample(routeColor);
    }
    this.employeesColors[employee._id] = employee.options.routeColor;
  }

  getEmployeeColor(userId) {
    if (userId == undefined) {
      return 'red';
    } else if (this.employeesColors[userId] != undefined) {
      return this.employeesColors[userId];
    } else {
      return 'red';
    }
  }

  async loadEmployeesInBackground() {
    const token = await this.storage.get('authorization-token');
    if (token == undefined) {
      return;
    }
    const employees = await this.storage.get('employees');
    if (employees != undefined) {
      this.employeesLoadedEvent.next(employees);
      employees.forEach(emp => {
        this.calculateEmployeesRoles(emp);
        this.getRouteColorsFromEmployee(emp);
      });
      this.employees = employees;
      this.calculateEmployeesStatus();
      setTimeout(() => {
        this.getAllEmployees();
      });
    } else {
      await this.getAllEmployees();
    }
  }

  private updateEmployeeStatus(data: any) {
    let modified = false;
    this.employees.forEach(e => {
      if (e._id == data.accountId) {
        e.online = {...(e.online ?? {}), ...(data.online ?? {})};
        modified = true;
      }
    });
    if (modified) {
      this.employeesLoadedEvent.next(this.employees);
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
    selector: 'app-activate-subscription-now',
    templateUrl: './activate-subscription-now.component.html',
    styleUrls: ['./activate-subscription-now.component.scss'],
})
export class ActivateSubscriptionNowComponent implements OnInit {

    @Input() subscription: {
      errorMessage: string;
      subscription: string;
      feature: string;
    };

    constructor(private modalController: ModalController, private router: Router) {
    }

    ngOnInit() {
    }

    closeModal() {
        this.modalController.dismiss();
    }

    async activateSubscription() {
        await this.modalController.dismiss();
        if (this.subscription != undefined) {
            await this.router.navigate(['/subscription/new-subscription'], {queryParams: {add: this.subscription.subscription, subscriptionRole: this.subscription.feature}});
        } else {
            await this.router.navigate(['/subscription/new-subscription']);
        }

    }

}

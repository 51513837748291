<ion-header>
  <ion-toolbar>
    <ion-title>{{'menu.switchAccount' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" color="dark" (click)="cancel()"><ion-icon name="close-outline"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding white-background">
  <ion-list [inset]="'full'">
    <ion-item *ngFor="let login of quickLogin;let i = index" lines="full">
      <ion-avatar>
        <ion-icon class="selected-account" *ngIf="login._id == accountProvider.account._id" name="checkmark-circle-outline"></ion-icon>
      </ion-avatar>
      <ion-label (click)="doQuickLogin(login)" class="ion-activatable ripple-parent rectangle">
        <h4>{{login.name}}</h4>
        <ion-note color="medium" class="ion-text-wrap">
          {{login.platform}}
        </ion-note>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-label>
      <ion-button slot="end" fill="clear" (click)="removeAccount(i)">
        <ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-item>

  </ion-list>
</ion-content>

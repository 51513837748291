import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable()
export class AppSessionService {
  private sessionId;

  constructor(private storage: Storage) {
  }

  async getSession() {
    if (this.sessionId) {
      return this.sessionId;
    }
    const session = await this.storage.get('lg_session');
    if (session) {
      this.sessionId = session;
      return this.sessionId;
    } else {
      this.sessionId = this.generator();
      await this.storage.set('lg_session', this.sessionId);
      return this.sessionId;
    }
  }


  public generator(): string {
    return `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;
  }

  private S4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  public async getFingerprint() {
    try {
      const fingerprint = await this.storage.get('lg_fingerprint');
      if (fingerprint != undefined) {
        return fingerprint;
      }
      const fpPromise = FingerprintJS.load();
      return fpPromise.then(fp => fp.get()).then(async result => {
        await this.storage.set('lg_fingerprint', result.visitorId);
        return result.visitorId;
      }).catch(err => {
        console.error(err);
        return undefined;
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

}

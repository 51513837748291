import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Diagnostic} from '@awesome-cordova-plugins/diagnostic/ngx';
import {BrowserLocationTrackingService} from '@app/map/route-monitoring/browser-location-tracking.service';
import {CordovaLocationTrackingService} from '@app/map/route-monitoring/cordova-location-tracking.service';
import {AskPermissionService} from '@app/map/route-monitoring/ask-mobile-permission/ask-permission.service';
import {
  AskMobilePermissionComponent
} from '@app/map/route-monitoring/ask-mobile-permission/ask-mobile-permission.component';
import {LocationTrackingService} from '@app/map/route-monitoring/location-tracking.service';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {RouteMonitoringService} from '@app/map/route-monitoring/route-monitoring.service';
import {LocationTracker} from '@app/map/route-monitoring/location-tracker';
import {Geolocation} from '@awesome-cordova-plugins/geolocation/ngx';

@NgModule({
  declarations: [AskMobilePermissionComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  providers: [Diagnostic, BrowserLocationTrackingService, CordovaLocationTrackingService, AskPermissionService, LocationTrackingService,
    Device, RouteMonitoringService, LocationTracker, Geolocation]
})
export class RouteMonitoringModule {
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Platform, ToastController} from '@ionic/angular';
import {Network} from '@awesome-cordova-plugins/network/ngx';
import {isMobile} from "@app/shared/service/application.service";


@Injectable({providedIn: 'root'})
export class AppOnlineService {
  public status = new BehaviorSubject(true);

  constructor(private platform: Platform,
              private network: Network,
              private toastController: ToastController) {
    this.loadStatus();
  }

  private loadStatus() {
    if (this.platform.is('cordova')) {
      this.status.next(navigator.onLine);
      this.network.onConnect().subscribe(value => {
        this.status.next(true);
      });
      this.network.onDisconnect().subscribe(value => {
        this.status.next(false);
        this.showOffline();
      });
    } else {
      /*window.addEventListener('online', () => {
        this.status.next(true);
      });
      window.addEventListener('offline', () => {
        this.status.next(false);
        this.showOffline();
      });*/
    }
  }

  showOnline() {
    this.toastController.create({
      position: 'top',
      duration: 1000,
      message: 'Offline',
      icon: 'cloud-offline-outline'
    }).then(toast => {
      toast.present();
    });
  }

  showOffline() {
    const opts: any = {
      position: 'top',
      message: 'Offline',
      icon: 'cloud-offline-outline',
      buttons: ['Ok']
    };
    if (isMobile()) {
      opts.duration = 5000;
    }
    this.toastController.create(opts).then(toast => {
      toast.present();
    });
  }
}

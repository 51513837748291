import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Storage} from '@ionic/storage-angular';
import {FirebaseService} from '@app/shared/service/firebase.service';
import {SocketService} from '@app/shared/service/socket.service';
import {StateStorageService} from '@app/shared/service/state-storage.service';
import {PushNotificationsService} from '@app/shared/service/push-notifications.service';
import {ConversionServiceService} from '@app/shared/service/conversion-service.service';
import {ModalController, Platform} from '@ionic/angular';
import {ActiveSubscriptionService} from '@app/shared/service/subscription.service';
import {AuthorityEnum} from '@app/shared/dto/authority.enum';
import {Events} from '@app/shared/service/events';
import {IAccountModel} from '@app/shared/dto/account.domain';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {TokenService} from '@app/shared/service/token.service';
import {AppSessionService} from '@app/shared/service/app-session.service';
import {MessagesService} from '@app/shared/service/messages.service';
import {FreeTrialExpiredComponent} from '@app/components/free-trial-expired/free-trial-expired.component';

@Injectable()
export class AccountProvider {
  public static account: any = {appSettings: {delivery: {distanceMeasurement: '', timeFormat: 'HH mm'}}};
  public account: IAccountModel & any = {
    appSettings: {delivery: {distanceMeasurement: '', timeFormat: 'HH mm'}},
    authorities: [],
    subscriptionRoles: []
  };
  public isLoggedIn = false;
  private authenticationState = new BehaviorSubject<any>(this.account);
  public newAccount = false;
  private messagesLoaded = false;
  constructor(private http: HttpClient,
              private router: Router,
              private translateService: TranslateService,
              private storage: Storage,
              private tokenService: TokenService,
              private firebaseService: FirebaseService,
              private stateStorageService: StateStorageService,
              private socketService: SocketService,
              private notificationService: PushNotificationsService,
              private conversionServiceService: ConversionServiceService,
              private activeSubscriptionService: ActiveSubscriptionService,
              private platform: Platform,
              private events: Events,
              private firebasex: FirebaseX,
              private appSessionService: AppSessionService,
              public messagesService: MessagesService,
              private modalController: ModalController) {
    this.events.subscribe('logoutAccount', async () => {
      this.isLoggedIn = false;
      this.softLogout();
    });
  }

  async signOutFromCordova() {
    if (this.platform.is('cordova') && await this.firebasex.isUserSignedIn()) {
      await this.firebasex.signOutUser();
    }
  }

  async getAccountDetails(force?: boolean) {
    if (this.account != undefined && this.account._id != undefined && !force) {
      return this.account;
    } else {
      return this.tokenService.getToken().then(token => {
        if (token) {
          return this.storage.get('account').then(account => {
            if (account) {
              this.account = account;
              this.isLoggedIn = true;
              this.authenticationState.next(this.account);
              AccountProvider.account = account;
              setTimeout(() => {
                this.getAccountFromServer();
              }, 1000);
              return this.account;
            } else {
              return this.getAccountFromServer();
            }
          });
        } else {
          return Promise.reject();
        }
      });
    }
  }


  initChatApp() {
    if (this.account != undefined && (window as any).tidioChatApi != undefined) {
      (document as any).tidioIdentify = {
        distinct_id: this.account._id,
        email: this.account.email,
        name: `${this.account.firstName} ${this.account.lastName}`
      };
      (window as any).tidioChatApi.setVisitorData({
        distinct_id: this.account._id,
        email: this.account.email,
        name: `${this.account.firstName} ${this.account.lastName}`
      });
    }

  }

  getPlatformName() {
    let platform;
    if (this.platform.is('android')) {
      platform = 'android';
    } else if (this.platform.is('ios')) {
      platform = 'ios';
    } else if (this.platform.is('ipad')) {
      platform = 'ipad';
    } else if (this.platform.is('iphone')) {
      platform = 'iphone';
    } else if (this.platform.is('cordova')) {
      platform = 'cordova';
    } else if (this.platform.is('phablet')) {
      platform = 'phablet';
    } else if (this.platform.is('tablet')) {
      platform = 'tablet';
    } else if (this.platform.is('electron')) {
      platform = 'electron';
    } else if (this.platform.is('pwa')) {
      platform = 'pwa';
    } else if (this.platform.is('desktop')) {
      platform = 'desktop';
    } else if (this.platform.is('mobileweb')) {
      platform = 'mobileweb';
    } else if (this.platform.is('mobile')) {
      platform = 'mobile';
    } else if (this.platform.is('hybrid')) {
      platform = 'hybrid';
    }

    return platform;
  }

  private async getAccountFromServer() {
    const params: any = {platform: this.getPlatformName()};
    await this.appSessionService.getFingerprint().then(fingerPrint => {
      if (fingerPrint) {
        params.fingerprint = fingerPrint;
      }
    });
    return this.http.get('/app/login/accountAndToken', {params}).toPromise().then(data => {
      this.account = data;
      AccountProvider.account = data;
      this.isLoggedIn = true;
      this.storage.set('account', data);
      this.firebaseService.signInUserInFirebase();
      this.socketService.connect();
      setTimeout(() => {
        this.authenticationState.next(this.account);
      }, 0);
      this.initChatApp();
      if (!this.messagesLoaded) {
        this.checkForAccountNotification();
        this.activeSubscriptionService.getActiveSubscriptions().then(subscriptions => {
          this.checkForFreeTrial(subscriptions);
        });
        this.messagesLoaded = true;
      }
      this.conversionServiceService.setUser(this.account._id);
      this.messagesService.getMessages();
      return this.account;
    }, (err) => {
      return Promise.reject();
    });
  }

  async checkForFreeTrial(subscriptions: any[]) {
    if (subscriptions.length > 0) {
      return;
    }
    if (!this.account.authorities.includes(AuthorityEnum.EDIT_SUBSCRIPTION) || this.account.qv == true) {
      return;
    }
    if (location.pathname.includes('new-subscription')) {
      return;
    }
    const modal = await this.modalController.create({
      component: FreeTrialExpiredComponent,
      backdropDismiss: false,
      keyboardClose: false,
    });
    await modal.present();
  }

  async resetAccount() {
    this.account = {langKey: this.account.langKey};
    this.isLoggedIn = false;
    await this.storage.remove('account');
    return this.getAccountDetails();
  }

  async logoutAccount() {
    if ((await this.tokenService.getToken()) != undefined) {
      this.http.post('/app/login/logout', {}).toPromise().catch(err => {
        console.log('ehh... could not logout');
      });
    }

    this.isLoggedIn = false;
    this.router.navigate(['/login/sign-in']).then(async () => {
      await Promise.all([
        this.tokenService.removeToken(),
        this.saveStorage(),
        this.firebaseService.stopFirebaseApp(),
        this.signOutFromCordova()
      ]).finally(() => {
        this.stateStorageService.resetPreviousState();
        this.account = {};
        AccountProvider.account = {};
        this.socketService.disconnect();
        setTimeout(() => {
          window.location.reload();
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }, 300);
      });
    });
  }

  private async saveStorage() {
    const keys = ['lg_session', 'lg_fingerprint', 'intro', 'navigationMode', 'lg_location_permission', 'freeTrialOffered', 'quick-login-token'];
    const savedKeys = {};
    for (const key of keys) {
      savedKeys[key] = await this.storage.get(key);
    }
    await this.storage.clear();
    for (const key of keys) {
      if (savedKeys[key] != undefined) {
        await this.storage.set(key, savedKeys[key]);
      }
    }
  }

  async saveAccount(account) {
    return this.http.post('/app/employee/createOrUpdateEmployee', account).toPromise();
  }
  async updateNavigation(account) {
    return this.http.post('/app/employee/updateNavigation', account).toPromise();
  }

  getAuthenticationState(): Observable<IAccountModel & any> {
    return this.authenticationState;
  }

  async getAuthenticationStatePromise(): Promise<IAccountModel & any> {
    return new Promise((resolve, reject) => {
      const subscription = this.authenticationState.subscribe(account => {
        if (account._id != undefined) {
          setTimeout(() => {
            subscription.unsubscribe();
          }, 100);
          resolve(account);
        }
      });
    });
  }

  hasAnyAuthority(authorities: string[]): boolean {
    if (!this.isLoggedIn || !this.account || !this.account.authorities) {
      return false;
    }

    for (let i = 0; i < authorities.length; i++) {
      if (this.account.authorities.includes(authorities[i])) {
        return true;
      }
    }

    return false;
  }

  hasAuthority(authority: string | AuthorityEnum): Promise<boolean> {
    if (!this.isLoggedIn) {
      return Promise.resolve(false);
    }

    return this.getAccountDetails().then(
      id => {
        return Promise.resolve(id.authorities && id.authorities.includes(authority));
      },
      () => {
        return Promise.resolve(false);
      }
    );
  }


  checkForAccountNotification() {
    if (this.account != undefined && this.account.notification != undefined) {
      if (this.account.notification.notifyOnOrderAssigned == true ||
        this.account.notification.onNewOrder == true ||
        this.account.notification.onDelivery == true) {
        this.notificationService.registerAccountToken();
      }
    }
  }

  async changeOnlineStatus(isOnline, accountId?) {
    await firstValueFrom(this.http.post('/app/employee/online', {isOnline, accountId}));
    if (accountId != undefined) {
      return;
    }
    if (this.account.online == undefined) {
      this.account.online = {};
    }
    this.account.online.isOnline = isOnline;
    this.storage.set('account', this.account);
    this.authenticationState.next(this.account);
  }

  get cantSeeOrders() {
    return this.account?.online?.onlineRequired == true &&
      this.account?.online.isOnline != true &&
      !this.hasAnyAuthority([AuthorityEnum.EDIT_ORDERS]);
  }

  public async softLogout() {
    await Promise.all([
      this.tokenService.removeToken(),
      this.saveStorage(),
      this.firebaseService.stopFirebaseApp(),
      this.signOutFromCordova()
    ]);
  }
}

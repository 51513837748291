export class GenericSearchDto {
  filters?: any = {};
  sorters?: {
    field: string,
    direction: 1 | -1
  }[] = [];
  fields?: string;
  page = 0;
  itemsPerPage = 10 || 'all';
  totalElements ? = 0;
  hasMorePages ? = true;

  constructor(filters?: any, sorters?: { field: string; direction: 1 | -1 }[], fields?: string) {
    this.filters = filters;
    this.sorters = sorters;
    this.fields = fields;
  }

  get totalElementsValue() {
    return this.totalElements;
  }

  set totalElementsValue(value: number) {
    this.totalElements = value;
    if (this.itemsPerPage == 'all') {
      this.hasMorePages = false;
    } else {
      this.hasMorePages = this.page + 1 < value / (this.itemsPerPage as number);
    }
  }

  get totalPages() {
    return Math.ceil(this.totalElements / (this.itemsPerPage as number));
  }

  incrementPage() {
    this.page = this.page + 1;
  }

  reset() {
    this.hasMorePages = true;
    delete this.totalElements;
    this.page = 0;
  }
}

export interface PageResponse<T> {
  content?: T[];
  page?: number;
  totalElements?: number;
}

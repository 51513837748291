import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {
  ActivateSubscriptionNowService
} from '@app/components/activate-subscription-now/activate-subscription-now.service';
import {Events} from '@app/shared/service/events';
import {Router} from '@angular/router';

// const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private toast: ToastController,
              private subscriptionService: ActivateSubscriptionNowService,
              private events: Events,
              private router: Router,
              private translate: TranslateService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/app/') || request.url.includes('/stock/') || request.url.includes('/billing/')) {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          this.handleCustomError(error);
          return throwError(error);
        })
      );
    } else {
      return next.handle(request);
    }
  }

  private handleCustomError(error: HttpErrorResponse): void {
    // Perform your custom error handling based on the error.status
    switch (error.status) {
      case 400:
        if (error && error.error && error.error.errorMessage) {
          this.toast.create({
            message: this.translate.instant(error.error.errorMessage, error.error?.data ?? {}),
            animated: true,
            buttons: [{
              text: this.translate.instant('generic.done'),
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked');
              }
            }],
            duration: 10000,
          }).then(toast => {
            document.body.appendChild(toast);
            toast.present();
          });
        }
        break;
      case 401:
        this.events.publish('logoutAccount');
        setTimeout(() => {
          this.router.navigate(['/login/sign-in']).then(() => {
            location.reload();
          });
        }, 300);
        break;
      case 402:
        this.subscriptionService.showActivateSubscription(error.error);
        break;
      case 500:
        if (error && error.error && error.error.errorMessage) {
          this.toast.create({
            message: this.translate.instant('service.http-interceptor.problemMessage', error.error?.data ?? {}),
            duration: 3000,
            animated: true
          }).then(toast => {
            document.body.appendChild(toast);
            toast.present();
          });
        }
        break;
      // Add more cases to handle other error statuses
      default:
        console.error(`An unknown error occurred: [${error.status}]`, error.message);
    }
  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Platform} from '@ionic/angular';
import {AccountProvider} from '@app/shared/service/account.service';
import {HttpClient} from '@angular/common/http';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {IntegrationService} from '@app/shared/service/integration.service';
import {TokenService} from "@app/shared/service/token.service";

@Injectable()
export class AccountResolver implements Resolve<any>, CanActivate {

    constructor(private accountProvider: AccountProvider,
                private router: Router) {
    }

    async isAccountLoaded() {
        if (this.accountProvider.isLoggedIn) {
            return Promise.resolve(true);
        } else {
            return this.accountProvider.getAccountDetails(true).then((account) => {
                return true;
            }).catch(() => {
                setTimeout(() => {
                  this.accountProvider.isLoggedIn = false;
                  this.router.navigate(['/login/sign-in'], {queryParamsHandling: 'merge'});
                }, 300);
                return false;
            });
        }
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.isAccountLoaded();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.isAccountLoaded();
    }
}

@Injectable()
export class ShopifyResolver implements CanActivate {

    constructor(private http: HttpClient,
                private iab: InAppBrowser,
                private router: Router,
                private platform: Platform,
                private integrationService: IntegrationService,
                private auth: TokenService) {

    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (route.queryParams !== undefined && route.queryParams.token !== undefined) {
            return this.auth.setToken(route.queryParams.token).then(() => {
                if (route.queryParams.redirect) {
                    location.replace(route.queryParams.redirect);
                } else {
                    this.router.navigate([''], {queryParamsHandling: 'merge'});
                }
                return Promise.resolve(true);
            });
        } else if (route.queryParams !== undefined && route.queryParams.hmac !== undefined) {
            this.integrationService.parentViewIntegrationUrl = route.queryParams.shop;
            return this.http.get('/app/shopify/createAccount', {params: route.queryParams}).toPromise().then((response: any) => {
                if (response.url !== undefined) {
                    if (this.platform.is('cordova')) {
                        this.iab.create(response.url, '_self', 'location=no');
                    } else {
                        location.replace(response.url);
                    }
                    return Promise.resolve(false);
                } else if (response.token !== undefined) {
                    this.auth.setToken(response.token).then(() => {
                        if (this.platform.is('cordova')) {
                            this.router.navigate([''], {queryParamsHandling: 'merge'});
                        } else {
                            if (window.location !== window.parent.location) {
                                this.router.navigate([''], {queryParamsHandling: 'merge'});
                            } else {
                                if (route.queryParams.shop != undefined && response.redirect != undefined) {
                                    location.replace(response.redirect);
                                } else {
                                    this.router.navigate([''], {queryParamsHandling: 'merge'});
                                }
                            }
                        }
                        return Promise.resolve(true);
                    });
                } else {
                    this.router.navigate([''], {queryParamsHandling: 'merge'});
                    return Promise.resolve(true);
                }
            });
        } else {
            this.router.navigate([''], {queryParamsHandling: 'merge'});
            return Promise.resolve(true);
        }

    }

}

import {Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Optional, Output, Renderer2} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatSelect} from '@angular/material/select';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {AppOnlineService} from '@app/shared/service/app-online.service';
import {Subscription} from 'rxjs';
import {MatMenuItem} from "@angular/material/menu";

@Directive({
  selector: '[isOnline]'
})
export class IsOnlineDirective implements OnInit, OnDestroy {
  private clickHandler: (event: Event) => void;
  private subsStatus: Subscription;
  @Output() onlineClick = new EventEmitter<Event>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private appOnlineService: AppOnlineService,
    @Optional() private matButton: MatButton,
    @Optional() private matSelect: MatSelect,
    @Optional() private matCheckbox: MatCheckbox,
    @Optional() private matSlideToggle: MatSlideToggle,
    @Optional() private matMenuItem: MatMenuItem
  ) {
    this.clickHandler = this.handleClick.bind(this);
  }

  ngOnInit(): void {
    this.updateElementState();
    this.subsStatus = this.appOnlineService.status.subscribe(value => {
      this.updateElementState();
    });
    this.renderer.listen(this.el.nativeElement, 'click', this.clickHandler);
  }

  ngOnDestroy(): void {
    if (this.subsStatus) {
      this.subsStatus.unsubscribe();
      delete this.subsStatus;
    }
  }

  private updateElementState(): void {
    const isOnline = this.appOnlineService.status.getValue();
    if (this.matButton) {
      this.matButton.disabled = !isOnline;
    } else if (this.matSelect) {
      this.matSelect.disabled = !isOnline;
    } else if (this.matCheckbox) {
      this.matCheckbox.disabled = !isOnline;
    } else if (this.matSlideToggle) {
      this.matSlideToggle.disabled = !isOnline;
    } else if (this.matMenuItem) {
      this.matMenuItem.disabled = !isOnline;
    } else {
      this.el.nativeElement.disabled = !isOnline;
    }
  }

  private handleClick(event: Event): void {
    const isOnline = this.appOnlineService.status.getValue();
    if (isOnline) {
      this.onlineClick.emit(event);
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}

import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {logEvent, setUserId, getAnalytics} from 'firebase/analytics';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {Platform} from '@ionic/angular';

declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class ConversionServiceService {

    constructor(private firebaseX: FirebaseX, private platform: Platform) {
    }

    capteraSignUpConversion() {
        if (environment.environment != 'production') {
            return;
        }
        try {
            const capterra_vkey = '8541f082ad5b31cb5eb9298f536d5968';
            const capterra_vid = '2145523';
            const capterra_prefix = (('https:' == document.location.protocol)
                ? 'https://ct.capterra.com' : 'http://ct.capterra.com');

            const ct = document.createElement('script');
            ct.type = 'text/javascript';
            ct.async = true;
            ct.src = capterra_prefix + '/capterra_tracker.js?vid='
                + capterra_vid + '&vkey=' + capterra_vkey;

            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(ct, s);
        } catch (e) {
            console.error(e);
        }
    }

    googleSignUp() {
        if (environment.environment != 'production') {
            return;
        }
        try {
            if (typeof gtag !== 'undefined') {
              gtag('event', 'sign_up', {method: 'Local'});
            }
            if (this.platform.is('cordova')) {
                this.firebaseX.logEvent('sign_up', {});
                this.firebaseX.logEvent('create_account', {});
            } else {
                logEvent(getAnalytics(), 'sign_up', {});
                logEvent(getAnalytics(), 'create_account', {});
            }
        } catch (e) {
            console.error(e);
        }
    }

    googleNewSubscription(subscriptionId, value, currency) {
        if (environment.environment != 'production') {
            console.log(subscriptionId, value, currency);
            return;
        }
        try {
            if (this.platform.is('cordova')) {
                this.firebaseX.logEvent('purchase', {
                    value,
                    currency,
                    transaction_id: subscriptionId
                });
            } else {
                logEvent(getAnalytics(), 'purchase', {
                    value,
                    currency,
                    transaction_id: subscriptionId
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    setUser(userID) {
        if (environment.environment != 'production') {
            return;
        }
        try {
            if (typeof gtag !== 'undefined') {
              gtag('set', {user_id: userID});
            }
            if (this.platform.is('cordova')) {
                this.firebaseX.setUserId(userID);
            } else {
                setUserId(getAnalytics(), userID);
            }

        } catch (e) {
            console.error(e);
        }
    }

}

<ion-app>
    <!--    <ion-split-pane [when]="applicationService.hideSidebar ? '':'xl'" contentId="main-content">-->
    <ion-menu class="main-menu" [ngClass]="{'hidden': !accountProvider.isLoggedIn}" type="overlay" contentId="main-content" swipeGesture="false" [dir]="i18nService.position">
        <ion-content>
            <div class="menu-header">
                <!--material-design-background-->
                <!--<img class="user-avatar round" [src]="chosenPicture || placeholder" onerror="this.src='assets/img/avatar/girl-avatar.png'"/>-->
              <h3 style="position: relative">Logistia <ion-menu-toggle [routerLink]="['/messages']" class="notification-icon">
                <span class="has-notification" *ngIf="messagesService.hasMessages"></span>
                <ion-icon name="notifications-outline"></ion-icon>
              </ion-menu-toggle></h3>
              <div class="account-name" (click)="switchAccountService.openSwitchAccount()">
                <i *ngIf="switchAccountService.canSwitchAccount" class="material-icons">switch_account</i>
                <span class="name">{{accountProvider.account?.firstName}}</span>
              </div>
                <!--                    <p class="e-mail">{{accountProvider.account.email}}</p>-->

            </div>
            <ion-list class="list white-background ion-text-center" lines="none">
                <ion-menu-toggle auto-hide="false">
                    <ion-item *hasAnyAuthority="'VIEW_DASHBOARD'" routerLinkActive="active" [routerDirection]="'root'"
                              [routerLink]="['/dashboard']" class="item ion-text-center"
                              lines="none">
                        <ion-label>
                            <ion-icon size="default" name="home"></ion-icon>
                            {{'menu.dashboardMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="['VIEW_DELIVERIES','VIEW_PICKUPS']" routerLinkActive="active" [routerDirection]="'root'"
                              [routerLink]="['/delivery']" class="item ion-text-center"
                              lines="none">
                        <ion-label>
                            <ion-icon size="default" name="car"></ion-icon>
                            {{'menu.deliveriesMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="'VIEW_ORDERS'" [routerDirection]="'root'" routerLinkActive="active" isOnline
                              [routerLink]="['/purchase-request']" class="item ion-text-center"
                              lines="none">
                        <ion-label>
                            <ion-icon size="default" name="basket"></ion-icon>
                            {{'menu.ordersMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="'VIEW_CUSTOMERS'" [routerDirection]="'root'" routerLinkActive="active" isOnline
                              [routerLink]="['/customers']" class="item ion-text-center"
                              lines="none">
                        <ion-label>
                            <ion-icon size="default" name="people-outline"></ion-icon>
                            {{'menu.customersMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="'VIEW_PRODUCTS'" [routerDirection]="'root'" routerLinkActive="active" isOnline
                              [routerLink]="['/products']" class="item ion-text-center"
                              lines="none">
                        <ion-label>
                            <ion-icon size="default" name="cube"></ion-icon>
                            {{'menu.productsMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="'VIEW_SUPPLIERS'" [routerDirection]="'root'" isOnline
                              routerLinkActive="active" [routerLink]="['/suppliers']" class="item ion-text-center"
                              lines="none">
                      <ion-label>
                        <ion-icon size="default" name="clipboard"></ion-icon>
                        {{'menu.suppliersMenu' | translate }}
                      </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="'VIEW_EMPLOYEES'" [routerDirection]="'root'" isOnline
                              routerLinkActive="active" [routerLink]="['/employees/list']" class="item ion-text-center"
                              lines="none">
                      <ion-label>
                        <ion-icon size="default" name="people"></ion-icon>
                        {{'menu.employeesMenu' | translate }}
                      </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="'VIEW_REPORTS'" [routerDirection]="'root'" isOnline
                              routerLinkActive="active" [routerLink]="['/reports']" class="item ion-text-center"
                              lines="none">
                        <ion-label>
                            <ion-icon size="default" name="bar-chart-outline"></ion-icon>
                            {{'menu.reportsMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="'VIEW_APP_SETTINGS'" [routerDirection]="'root'"
                              routerLinkActive="active" [routerLink]="['/app-settings']" class="item ion-text-center"
                              lines="none">
                        <ion-label>
                            <ion-icon size="default" name="build"></ion-icon>
                            {{'menu.settingsMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item [routerDirection]="'root'" routerLinkActive="active" [routerLink]="['/employees/my-account']"
                              class="item ion-text-center"
                              lines="none" *ngIf="accountProvider.isLoggedIn">
                      <ion-label>
                        <ion-icon size="default" name="person"></ion-icon>
                        {{'menu.accountMenu' | translate }}
                      </ion-label>
                    </ion-item>
                    <ion-item *hasAnyAuthority="'VIEW_SUBSCRIPTION'" [routerDirection]="'root'" routerLinkActive="active" [routerLink]="['/subscription']" isOnline
                              class="item ion-text-center"
                              lines="none">
                        <ion-label>
                            <ion-icon size="default" name="card-outline"></ion-icon>
                            {{'menu.subscriptionMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item [routerDirection]="'root'" routerLinkActive="active" [routerLink]="['/feedback']"
                              class="item ion-text-center"
                              lines="none" *ngIf="accountProvider.isLoggedIn">
                        <ion-label>
                            <ion-icon size="default" name="at"></ion-icon>
                            {{'menu.contactMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <hr style="background: whitesmoke;margin-left: 30px;">
                    <ion-item
                      (click)="switchAccountService.openSwitchAccount()"
                      *ngIf="accountProvider.isLoggedIn && switchAccountService.canSwitchAccount">
                        <ion-label>
                          <ion-icon size="default" name="people-circle-outline"></ion-icon>
                          {{'menu.switchAccount' | translate }}
                        </ion-label>
                    </ion-item>
                    <ion-item
                      (click)="accountProvider.logoutAccount()"
                      *ngIf="accountProvider.isLoggedIn && !applicationService.hideLogout">
                        <ion-label>
                            <ion-icon size="default" name="exit"></ion-icon>
                          {{'menu.logoutButton' | translate }}
                        </ion-label>
                    </ion-item>
                    <hr>
                    <ion-item (click)="rateApp()" style="font-size: 14px" *ngIf="canRate" >
                        <ion-label>
                            <ion-icon size="default" name="star"></ion-icon>
                          {{'menu.rateMenu' | translate }}
                        </ion-label>
                    </ion-item>
                    <app-whats-new></app-whats-new>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
        <ion-footer class="footer">
            <ion-grid>
                <span>v {{env.appVersion}}</span>
            </ion-grid>
        </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content" [dir]="i18nService.position"></ion-router-outlet>
    <div class="timeWindowWarning" *ngIf="showTimeWarning!=undefined">{{'menu.timezoneWarningMessage' | translate:{showTimeWarning:showTimeWarning} }}</div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

    <!--    </ion-split-pane>-->
</ion-app>


import packageJson from '../../package.json';

export const environment = {
    production: true,
    serviceWorker: true,
    environment: 'QA',
    serverUrl: 'https://qa-api.logistia.app',
    socket: {
        url: 'wss://ws.logistia.app/qa'
    },
    amazonS3: '',
    cardPayment: {
      provider: 'stripe-card',
      stripeKey: 'pk_test_EcWY4i7FmVOWsbXACF8hNUXk',
    },
    portalUrl: 'https://portal-qa.logistia.app',
    appVersion: packageJson.version,
    firebaseConfig: {
        apiKey: 'AIzaSyCGu6FNz0dp_lwEODH6v9BWATcEbpLFIk4',
        authDomain: 'auth.logistia.app',
        databaseURL: 'https://logistic-3cf99.firebaseio.com',
        projectId: 'logistic-3cf99',
        storageBucket: 'logistic-3cf99.appspot.com',
        messagingSenderId: '329871317734',
        appId: '1:329871317734:web:75612c244b4dfb2de27927',
        measurementId: 'G-L927E7RPZF'
    },
    capchaKey: '6LfV5KoZAAAAAEfSyEiXPJJO6t_rgbxYbSJy1_zr',
    mapboxKey: 'pk.eyJ1Ijoidmxka3J0IiwiYSI6ImNraHdodDEzYjA4cjYzN25iaG9tczVudWQifQ.AXv1hbPvy4A2o_49PanR2A'
};

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountResolver} from '@app/core';
import {RedirectGuard} from '@app/shared/service/redirect.guard';
import {EmployeeResolverService} from '@app/shared/service/employee-resolver.service';

const routes: Routes = [
    {
        path: 'shopify',
        loadChildren: () => import('@app/loading/loading.module').then(m => m.LoadingPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('@app/login/login.module').then(m => m.LoginModule)
    },
    {
        path: '',
        loadChildren: () => import('@app/main/main.module').then(m => m.MainModule),
        resolve: {employees: EmployeeResolverService},
        canActivate: [AccountResolver]
    },
    {
        path: 'subscription',
        loadChildren: () => import('./main/subscription/subscription.module').then(m => m.SubscriptionPageModule)
    },
    {
        path: 'token',
        loadChildren: () => import('./token/token.module').then(m => m.TokenPageModule)
    },
    {
        path: 'reports',
        canActivate: [AccountResolver],
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsPageModule)
    },
    {
        path: 'email-subscribe',
        loadChildren: () => import('./email-subscribe/email-subscribe.module').then(m => m.EmailSubscribePageModule)
    },
    {
        path: 'squarespace',
        loadChildren: () => import('./squarespace/squarespace.module').then( m => m.SquarespacePageModule)
    },
    {
      path: 'new-order',
      loadChildren: () => import('./main/new-order/new-order.module').then( m => m.NewOrderPageModule)
    },
    {
      path: 'messages',
      loadChildren: () => import('./main/messages/messages.module').then( m => m.MessagesPageModule),
      canActivate: [AccountResolver]
    },
    {path: '**',
      redirectTo: ''
  }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [RedirectGuard]
})
export class AppRoutingModule {
}

import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Platform, ToastController} from '@ionic/angular';
import {FirebaseX} from '@awesome-cordova-plugins/firebase-x/ngx';
import {Messaging, isSupported, getMessaging, getToken, onMessage} from 'firebase/messaging';
import {getApps, initializeApp} from 'firebase/app';
import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationsService {
    messaging: Messaging;
    platformName;
    vapidKey = 'BKJOZK7RaPkE0zHf07t9VNGCrzCcosQALrWtjMMgGWidemu4FDRhoc3oSiuneFoXetk_gwKiho2hTLQ9kR1o6po';
    constructor(private httpClient: HttpClient,
                private platform: Platform,
                private firebaseX: FirebaseX,
                private toastService: ToastController,
                private translateService: TranslateService) {
        if (platform.is('cordova') && platform.is('ios')) {
            this.platformName = 'ios';
        } else if (platform.is('cordova') && platform.is('android')) {
            this.platformName = 'android';
        } else {
            this.platformName = 'browser';
            try {
                if (getApps().length === 0) {
                    initializeApp(environment.firebaseConfig);
                }
                isSupported().then(value => {
                  if (value) {
                    this.messaging = getMessaging();
                    this.handleBrowserNotification();
                  } else {
                    console.log('Messaging is not supported');
                  }
                });
            } catch (e) {
                console.error(e);
            }
        }

    }

    check = () => {
        if (!('serviceWorker' in navigator)) {
            return false;
        }
        if (!('PushManager' in window)) {
            return false;
        }
        return true;
    }

    async registerAccountToken() {
        if (this.platform.is('cordova')) {
          this.firebaseX?.onTokenRefresh()?.subscribe(async (token: string) => {
            await this.saveToken(token);
          });
          await this.initPushNotification();
          this.handleMobileNotification();
        } else if (this.messaging) {
            await this.initBrowserNotification();
        }
    }

    async initBrowserNotification() {
        return getToken(this.messaging, {vapidKey: this.vapidKey}).then((currentToken) => {
            if (currentToken) {
                return this.saveToken(currentToken);
            } else {
                // Show permission request.
                console.log('No Instance ID token available. Request permission to generate one.');
                // Show permission UI.
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
    }

    async handleBrowserNotification() {
      onMessage(this.messaging, (data) => {
        let msg = '';
        if (data.data.title?.length > 0) {
          msg = msg + data.data.title + '.';
        }
        if (data.data.message?.length > 0) {
          msg = msg + ' ' + data.data.message;
        }
        if (msg.length > 0) {
          this.toastService.create({
            message: msg,
            duration: 3000,
            buttons: [this.translateService.instant('generic.okButton')]
          }).then(toast => toast.present());
        }
        console.log(data);
      });
    }

    async handleMobileNotification() {
      this.firebaseX.onMessageReceived().subscribe(value => {
        if (value?.aps != undefined) {
          this.handleMobileNotif(value.aps?.alert ?? {});
        } else {
          this.handleMobileNotif(value);
        }
      });
    }

    private handleMobileNotif(value) {
      let msg = '';
      if (value.title?.length > 0) {
        msg = msg + value.title + '.';
      }
      if (value?.body?.length > 0) {
        msg = msg + ' ' + value.body;
      }
      if (value.data != undefined && value.data.length > 0) {
        let data;
        try {
          data = JSON.parse(value.data);
        } catch (e) {
          console.error(e);
        }
        if (data != undefined && data?.message?.length > 0) {
          msg = msg + ' ' + data.message;
        }
      } else if (value.payload != undefined && value.payload.length > 0) {
        let data;
        try {
          data = JSON.parse(value.payload);
        } catch (e) {
          console.error(e);
        }
        if (data != undefined && data?.message?.length > 0) {
          msg = msg + ' ' + data.message;
        }
      }

      if (msg.length > 0) {
        this.toastService.create({
          message: msg,
          duration: 3000,
          buttons: [this.translateService.instant('generic.okButton')]
        }).then(toast => toast.present());
      }
    }

    async initPushNotification() {
        const hasPermission = await this.firebaseX.hasPermission();
        if (!hasPermission) {
            await this.firebaseX.grantPermission();
        }
        await this.firebaseX.getToken().then(async token => {
            await this.saveToken(token);
        });
    }

    private async saveToken(token) {
      if (token == null) {
        console.error('Token is null and it won\'t be saved');
        return;
      }
      const body = {token, platform: this.platformName};
      await firstValueFrom(this.httpClient.post('/app/login/register-notification-token', body));
    }
}

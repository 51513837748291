import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {SubscriptionRoles} from '@app/shared/dto/subscription-roles.dto';
import {environment} from '@env/environment';

@Injectable({providedIn: 'root'})
export class ActiveSubscriptionService {
    activeSubscriptions = [];
    subscriptionFetched = false;
    activeSubscriptionSubject = new ReplaySubject<any[]>(1);
    constructor(private httpClient: HttpClient, private storage: Storage, public dialog: MatDialog, private router: Router) {

    }

    async getActiveSubscriptions(force?: boolean): Promise<any[]> {
        if (this.subscriptionFetched == false || force == true) {
            this.activeSubscriptions = await this.httpClient.get<any>('/billing/subscription/activeSubscriptions').toPromise();
            this.activeSubscriptions.forEach(subs => {
              subs.almostFull = this.getSubscriptionAlmostFull(subs);
            });
            this.subscriptionFetched = true;
            this.activeSubscriptionSubject.next(this.activeSubscriptions);
        }
        return this.activeSubscriptions;
    }

  getSubscriptionAlmostFull(subscription) {
    if (subscription.status == 'active' &&
      subscription.suspendedError == undefined &&
      subscription?.endDate == undefined &&
      subscription?.paymentMethod?.processor == environment.cardPayment.provider) {
      return (subscription.usedNumberOfOrders / subscription.totalDeliveryOrders) > 0.9;
    } else {
      return false;
    }
  }

    async hasFreeOrMobileSubscription() {
      const activeSubscription = await this.getActiveSubscriptions();
      return activeSubscription.length == 0 || (activeSubscription.find(s => s.plan == 'free' || s.plan == '1000_single_false') != undefined);
    }

    async hasActiveSubsRole(role: SubscriptionRoles) {
      const activeSubscriptions = await this.getActiveSubscriptions();
      return activeSubscriptions?.length > 0 && activeSubscriptions?.find(s => s.subscriptionRoles?.indexOf(role) > -1) != undefined;
    }

    async activeSubscriptionsForRenew() {
        return this.httpClient.get<any>('/billing/subscription/activeSubscriptionsForRenew').toPromise();
    }


    async cancelSubscription(subscription: any, review) {
        return this.httpClient.post<any>('/billing/subscription/cancelSubscription',
          {subscriptionId: subscription._id, feedback: review.feedback, reviewValue: review.value})
          .toPromise();
    }

    async saveCompanyBillingDetails(billingDetails: any) {
        return this.httpClient.post<any>('/billing/billing/companyDetails', billingDetails).toPromise();
    }

    async getCompanyBillingDetails() {
        return this.httpClient.get<any>('/billing/billing/companyDetails').toPromise();
    }

    async getPayments() {
        return this.httpClient.get<any>('/billing/payment/payments').toPromise();
    }

    async validateVies(companyDetails) {
        if (companyDetails.registrationNumber == undefined || companyDetails.registrationNumber.length == 0) {
          return [];
        }
        return this.httpClient.post<any>('/billing/billing/validateVies', companyDetails).toPromise();
    }

    async addNewCard(card, currency) {
        return this.httpClient.post<any>('/billing/billing/addNewCard', {
            cardId: card.cardId,
            transactionId: card.transactionId,
            currency
        }).toPromise();
    }

    async setCardAsPrimary(paymentMethodId) {
        return this.httpClient.post<any>('/billing/billing/setPrimary', {
            paymentMethodId
        }).toPromise();
    }

    async deleteCard(paymentMethodId) {
        return this.httpClient.post<any>('/billing/billing/deleteCard', {
            paymentMethodId
        }).toPromise();
    }

    async downloadInvoice(paymentId) {
        return this.httpClient.get<string>('/billing/payment/downloadInvoice', {
            params: {paymentId},
            observe: 'response'
        });
    }

    async validateShopifyPayment(charge_id: any) {
        return this.httpClient.get<any>('/billing/payment/validateShopifyPayment', {
            params: {charge_id}
        }).toPromise();
    }
}

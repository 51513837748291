import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AccountProvider} from '@app/shared/service/account.service';
import {TokenService} from '@app/shared/service/token.service';

@Component({
  selector: 'app-switch-account',
  templateUrl: './switch-account.component.html',
  styleUrls: ['./switch-account.component.scss'],
})
export class SwitchAccountComponent implements OnInit {

  quickLogin = [];

  constructor(private modalController: ModalController,
              public accountProvider: AccountProvider,
              private tokenService: TokenService) {

  }

  async ngOnInit() {
    this.quickLogin = await this.tokenService.getQuickLoginAccounts();
  }


  cancel() {
    this.modalController.dismiss();
  }


  doQuickLogin(login: any) {
    if (login._id == this.accountProvider.account._id) {
      this.modalController.dismiss();
    } else {
      this.modalController.dismiss(login, 'doQuickLogin');
    }
  }

  removeAccount(i: any) {
    this.quickLogin.splice(i, 1);
    this.tokenService.saveQuickLogin(this.quickLogin);
    this.modalController.dismiss();
  }
}

import {Injectable, NgZone} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Events} from '@app/shared/service/events';

@Injectable()
export class IntegrationService {
    public integrations = [];
    private integration: any = {_id: undefined};
    public parentViewIntegrationUrl;
    public localIntegrationId;
    public loadingIntegrations = false;
    private integrationsLoaded = false;
    private integrationSubject: Subject<any> = new Subject();

    constructor(private http: HttpClient,
                private iab: InAppBrowser,
                private ngZone: NgZone,
                private events: Events,
                private activatedRoute: ActivatedRoute) {
        activatedRoute.queryParams.subscribe(params => {
            if (params.integrationId != undefined) {
                this.integration._id = params.integrationId;
            }
            if (window.location.pathname != '/app-settings') {
              this.init();
            }
        });
    }

    reset() {
        this.integrations = [];
        this.integration = {_id: undefined};
        this.parentViewIntegrationUrl = undefined;
        this.localIntegrationId = undefined;
        this.loadingIntegrations = false;
        this.integrationsLoaded = false;
    }

    init() {
        this.getIntegrations();
        this.events.subscribe('integration:refresh', () => {
            this.getIntegrations(true);
        });
    }

    getCurrentIntegration(): Observable<any> {
        return new Observable(subscriber => {
            if (this.integration?._id != undefined && this.integrationsLoaded) {
                subscriber.next(this.integration);
                subscriber.complete();
            } else {
                this.integrationSubject.subscribe(resp => {
                    subscriber.next(resp);
                    subscriber.complete();
                });
            }
        });
    }

    get _integration() {
        return this.integration;
    }

    set _integration(integration) {
        this.integration = integration;
    }

    changeIntegrationToLocal() {
        const foundIntegration = this.integrations.find((integration) => {
            return integration.platform == 'local';
        });
        this.changeCurrentIntegration(foundIntegration._id);
    }

    private loadDefaultIntegration() {
        if (this.integrations.length == 1) {
            this.integration = this.integrations[0];
        } else {
            if (this.parentViewIntegrationUrl != undefined) {
                this.integration = this.integrations.find((integration) => {
                    return integration.originUrl != undefined && integration.originUrl.includes(this.parentViewIntegrationUrl);
                });
            } else if (this.integration._id != undefined) {
                this.integration = this.integrations.find((integration) => {
                    return integration._id == this.integration._id;
                });
            } else {
                const integration = this.integrations.find((integration) => {
                    return integration.stockSync.primary;
                });
                if (integration == undefined) {
                    const externalIntegration = this.integrations.find((integration) => {
                        return !['local','api','Zapier'].includes(integration.platform)
                    });
                    if (externalIntegration) {
                      this.integration = externalIntegration
                    } else {
                      this.integration = this.integrations.find((integration) => {
                        return integration.platform == 'local';
                      })
                    }
                } else {
                    this.integration = integration;
                }
            }
        }
    }

    async getIntegrations(reload?, verify?) {
        if (verify == true) {
            this.loadingIntegrations = false;
        }
        if (!this.loadingIntegrations && (reload || this.integrations.length == 0)) {
            this.loadingIntegrations = true;
            return this.http.get('/app/integration/integrations', {params: {verify: verify || false}})
                .toPromise().then((integrations: any) => {
                    this.integrations = [];
                    this.integrations = integrations;
                    this.loadDefaultIntegration();
                    this.events.publish('integration:loaded');
                    integrations.forEach(i => {
                        if (i.platform == 'local') {
                            this.localIntegrationId = i._id;
                        }
                    });
                    this.loadingIntegrations = false;
                    this.integrationsLoaded = true;
                    this.events.publish('fullstory:data', {
                        platorm: this.integrations.map(i => i.platform),
                        platormName: this.integrations.map(i => i.platformName),
                    });
                    this.integrationSubject.next(this.integrations);
                    return this.integrations;
                }).catch((e) => {
                    this.loadingIntegrations = false;
                    return e;
                });
        } else if (this.loadingIntegrations) {
            return new Promise(resolve => {
                this.integrationSubject.subscribe(integrations => {
                    resolve(integrations);
                });
            });
        } else {
            return Promise.resolve(this.integrations);
        }

    }

    async getLocalIntegration() {
      let integrations = await this.getIntegrations();
      return integrations.find(i => i.platform == "local")
    }

    async hasNoEcommerceIntegration(): Promise<boolean> {
      const integrations = await this.getIntegrations();
      const find = integrations.find(i => !['local','api','Zapier'].includes(i.platform));
      return find == undefined;
    }

    saveIntegration(integration) {
        return this.http.post('/app/integration/integration', integration).toPromise();
    }

    validateEmail(integration) {
        return this.http.post('/app/integration/validate-email', integration).toPromise();
    }

    syncCategories(integration) {
        return this.http.get('/stock/integration/syncCategories', {params: {integrationId: integration._id}}).toPromise();
    }

    startShopifyIntegration(shop) {
        this.http.get('/app/shopify/startIntegration', {params: {shop: shop}}).subscribe((data: any) => {
            this.iab.create(data.url, '_blank', 'location=no');
        });
    }

    async startMagentoIntegration(url, consumerKey, consumerSecret, accessToken, accessTokenSecret) {
        return this.http.post('/app/magento/startIntegration', {
            url: url,
            consumerKey: consumerKey,
            consumerSecret: consumerSecret,
            accessToken: accessToken,
            accessTokenSecret: accessTokenSecret
        }).toPromise();
    }

    startWoocommerceIntegration(shop: any) {
        this.http.get('/app/woocommerce/startIntegration', {params: {shop: shop}}).subscribe((data: any) => {
            this.iab.create(data.url, '_blank', 'location=no');
        });
    }

    startSquarespaceIntegration() {
        this.http.get('/app/squarespace/startIntegration').subscribe((data: any) => {
            this.iab.create(data.url, '_blank', 'location=no');
        });
    }
    startQuickbooksIntegration() {
        this.http.get('/app/quickbooks/startIntegration').subscribe((data: any) => {
            this.iab.create(data.url, '_blank', 'location=no');
        });
    }

    startEMagIntegration(eMag: any) {
        return this.http.post('/app/emag/startIntegration', eMag).toPromise();
    }

    async setIntegrationPrimary(integration: any) {
        return this.http.post('/app/integration/setPrimary', integration).toPromise();
    }

    async syncStocks() {
        return this.http.get('/stock/integration/syncStocks').toPromise();
    }

    getIntegrationsLoaded() {
        return this.integrationsLoaded;
    }

    changeCurrentIntegration(integrationId: string) {
        if (this.integrationsLoaded) {
            this.integration = this.integrations.find(i => i._id == integrationId);
        } else {
            this.integrationSubject.subscribe(resp => {
                this.integration = this.integrations.find(i => i._id == integrationId);
            });
        }
    }

    startCelIntegration(cel: { password: any; username: any }) {
        return this.http.post('/app/cel/startIntegration', cel).toPromise();
    }


    startNewApiIntegration(api: { orderUpdatesUrl?: any, platform?: string }) {
        return this.http.post('/app/integration/api-integration', api).toPromise();
    }
}
